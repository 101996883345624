import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import { UppySettings } from "../../webpack/javascripts/components/wizard/uppy";
import { Fetcher } from "../../webpack/javascripts/fetcher";
import { Modal } from "bootstrap";

interface FinalizeResponse {
  destination: string;
}

export default class extends Controller {
  dashboardTarget: HTMLElement;
  modalTarget: HTMLElement;
  textareaTarget: HTMLTextAreaElement;
  textareaModalTarget: HTMLTextAreaElement;
  propsValue: UppySettings;
  tokenValue: string;
  typeValue: string;
  submitButtonTargets: HTMLButtonElement[];

  static targets = ["dashboard", "modal", "textarea", "textareaModal", "submitButton"];

  static values = {
    props: Object,
    token: String,
    type: String
  };

  private uppyLoaded: boolean;
  private uppy: Uppy;

  connect() {
    this.uppyLoaded = false;
    $(this.modalTarget).on("shown.bs.modal", () => {
      // trigger resize event; see expandable textarea controller
      this.textareaModalTarget.dispatchEvent(new Event("input"));
    });
  }

  validateModalForm(event) {
    if (this.uppy.getFiles().length === 0 && this.textareaModalTarget.value.length === 0) {
      event.preventDefault();
      event.stopImmediatePropagation(); // abort any other event handlers
    }
  }

  // before sending the form, disable all buttons and textareas
  beforeSend(event) {
    this.submitButtonTargets.forEach((button) => {
      button.disabled = true;
    });

    this.textareaModalTarget.disabled = true;
    this.textareaTarget.disabled = true;
  }

  // open comment with attachment modal
  openModal(event: Event) {
    event.preventDefault();

    this.loadUppy().then(() => {
      // propagate textarea content to modal textarea
      if (this.textareaModalTarget.value === "") {
        this.textareaModalTarget.value = this.textareaTarget.value;
      }
      const modal = new Modal(this.modalTarget);
      modal.show();
    });
  }

  // success json response from the backend
  success(event) {
    const [data, _status, _xhr] = event.detail;
    window.location = data.destination;
  }

  // error json response from the backend
  error(event) {
    const [data, _status, _xhr] = event.detail;
    window.location = data.destination;
  }

  async loadUppy(): Promise<void> {
    if (this.uppyLoaded) {
      return Promise.resolve();
    }

    await import("@uppy/dashboard").then(async (dashboard) => {
      await import("../../webpack/javascripts/components/wizard/uppy").then(
        ({ initUppy }) => {
          this.uppy = initUppy(this.propsValue, false).use(dashboard.default, {
            inline: true,
            target: this.dashboardTarget,
            showProgressDetails: true,
            plugins: [],
            proudlyDisplayPoweredByUppy: false,
            showSelectedFiles: true,
            hideUploadButton: true,
            width: "100%",
            height: "100%"
          });

          this.uppyLoaded = true;
        }
      );
    });
  }

  // async comment with upload
  successWithUpload(event) {
    const [data, status, xhr] = event.detail;

    // at this point we already created a comment record
    // now upload remaining files
    this.uppy.setMeta({ upload_parent_id: data.id });
    this.uppy.setMeta({ upload_type: this.typeValue });
    this.uppy.setMeta({ token: this.tokenValue });
    this.uppy.on("complete", (uploadResult) => {
      // and finalize comment creation (create feeds and trigger notification)
      const fetcher = new Fetcher();
      fetcher
        .request(data.finalize_path, {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((json) => {
          // TODO: check uploadResult.failed.length and handle errors?
          window.location.href = (json as unknown as FinalizeResponse).destination;
        });
    });

    this.uppy.upload();
  }
}
