function setEndOfContenteditable(contentEditableElement) {
  const range = document.createRange()
  const selection = window.getSelection()

  range.selectNodeContents(contentEditableElement)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
}

function formatValue(element, inputField) {
  if (inputField.dataset.category === "final_cleaning" && element.value === "6") {
    return "6+"
  }

  return element.value
}

function modifyOffset() {
  const max = parseFloat(this.getAttribute('max'))
  const min = parseFloat(this.getAttribute('min'))

  if (this.parentElement.getElementsByClassName('range-slider-value')) {
    const rangeSliderInputField = this.parentElement.getElementsByClassName('range-slider-value')[0]

    if (rangeSliderInputField) {
      rangeSliderInputField.innerText = formatValue(this, rangeSliderInputField)
    }

    if (rangeSliderInputField && !rangeSliderInputField.onblur) {
      rangeSliderInputField.onblur = () => {
        const value = parseInt(rangeSliderInputField.innerHTML)

        if (!value || value < min) {
          rangeSliderInputField.innerText = min
          rangeSliderInputField.oninput()
        }
      }
    }

    if (rangeSliderInputField && !rangeSliderInputField.oninput) {
      rangeSliderInputField.oninput = () => {
        let value = parseInt(rangeSliderInputField.innerHTML)

        if (!value || value < min) return

        if (value > max) {
          value = this.getAttribute('max')
          rangeSliderInputField.innerText = value
        }

        this.value = value
        this.oninput()

        setEndOfContenteditable(rangeSliderInputField)
        rangeSliderInputField.dispatchEvent(new Event('input-over'))
      }
    }
  }

  const totalRange = (max - min)
  const offsetPercentage = (min / totalRange) * 100
  const coveragePercentage = this.value / totalRange * 100 - offsetPercentage
  this.style.background = `linear-gradient(to right, #3258A7FF 0%, #3258A7FF ${coveragePercentage}%, #e1e3e5 ${coveragePercentage}%)`
}

export const initRangeInputs = () => {
  const inputs = document.getElementsByClassName('range-input')
  for (let i = 0; i < inputs.length; i += 1) {
    if(!inputs[i].oninput) {
      inputs[i].oninput = modifyOffset
    }

    inputs[i].oninput()
  }
}
