import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";

export default class extends Controller {
  static values = {
    userId: String,
    eventName: String,
    eventProperties: Object,
    trackingUrl: String,
    href: String,
  };

  private fetcher: Fetcher;
  private userIdValue: string;
  private eventNameValue: string;
  private eventPropertiesValue: any;
  private trackingUrlValue: string;

  connect() {
    this.fetcher = new Fetcher();
  }

  track() {
    this.fetcher.request(this.trackingUrlValue, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: {
          user_id: this.userIdValue,
          name: this.eventNameValue,
          properties: this.eventPropertiesValue,
        },
      }),
    });
  }
}
