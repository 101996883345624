import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import {
  notification,
  serverErrorNotification,
} from "../components/notification";

export default class extends Controller {
  static targets = ["invitationBlock", "invitationLink"];

  static values = {
    createUrl: String,
    disabledText: String,
    tradesmanId: Number,
    jobId: Number,
    redirectTo: String,
    nextPageButton: String,
    nextPageAfterText: String,
  };

  static outlets = ["invitations-info-box"];

  connect() {
    this.fetcher = new Fetcher();
  }

  createInvitation(event) {
    event.preventDefault();
    if (this.redirect()) return;

    this.fetcher
      .request(this.createUrlValue, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.invitationLinkTarget.setAttribute("disabled", true);
        this.invitationLinkTarget.classList.add("disabled");
        this.invitationLinkTarget.textContent = this.disabledTextValue;
        this.updateInfoBox();
        this.changeNextPageButton(
          document.getElementsByClassName(this.nextPageButtonValue),
        );
        notification(res.message, "notice");
      })
      .catch(() => {
        serverErrorNotification();
      });
  }

  redirect() {
    const url = this.redirectToValue;

    if (url) {
      window.location.href = url;
      return true;
    }
  }

  deleteInvitationFromSession() {
    event.preventDefault();

    this.fetcher
      .request("/invitations/delete_invitation_from_session", {
        method: "delete",
      })
      .then((response) => {
        if (response.error) {
          notification(response.error, "error");
        } else {
          this.invitationBlockTarget.innerHTML = "";
        }
      });
  }

  updateInfoBox() {
    const infoBox = document.getElementsByClassName("info_box_text")[0];
    if (!infoBox) return;

    let availableSuggestions = parseInt(infoBox.dataset.available);
    let alreadySuggested = parseInt(infoBox.dataset.used);

    if (availableSuggestions > 0) {
      availableSuggestions -= 1;
      alreadySuggested += 1;

      infoBox.dataset.available = availableSuggestions;
      infoBox.dataset.used = alreadySuggested;

      this.invitationsInfoBoxOutlet.update(
        infoBox.dataset.used,
        infoBox.dataset.available,
      );
    }
  }

  changeNextPageButton(elements) {
    if (!elements) return;

    Array.from(elements).forEach((element) => {
      element.textContent = this.nextPageAfterTextValue;
      element.classList.replace("v2-btn-secondary", "v2-btn-primary");
    });
  }
}
