import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["triggerPhonePopup"];

  private hasTriggerPhonePopupTarget: boolean;

  private triggerPhonePopupTarget: HTMLElement;

  connect(): void {
    if (this.hasTriggerPhonePopupTarget) {
      this.triggerPhonePopupTarget.classList.remove("d-none");
    }

    $.colorbox({
      inline: true,
      transition: "none",
      fixed: true,
      href: "#phone-verification-modal",
    });
  }

  trigger(): void {
    $.colorbox({
      inline: true,
      transition: "none",
      fixed: true,
      href: "#phone-verification-modal",
    });
  }
}
