import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

const BASE_AREA = "BASE_AREA";
const SPECIFIC_AREA = "SPECIFIC_AREA";

// noinspection DuplicatedCode,JSUnresolvedVariable
export default class extends CostEstimatorController {
  static values = { roomOrSurfaceTranslations: Object };

  static targets = [
    "insidePaintingForm",
    "insideBaseAreaBlock",
    "insideSpecificAreaBlock",
    "paintingRoomsOrSurfaceTitle",
    "walls",
    "ceilings",
    "floors",
    "windows",
    "doors",
  ];

  connect() {
    super.connect();
    this.initValues();

    initRangeInputs();
    initChipCheckboxes();

    this.calculateData();
  }

  initValues() {
    this.areaToPaint = BASE_AREA;
    this.insideBaseAreaPrices = this.calculationDataValue["inside_base_area"];
    this.insideSpecificAreaPrices =
      this.calculationDataValue["inside_specific_area"];
    this.minimumWindowsPrices =
      this.calculationDataValue["minimum_windows_prices"];
    this.minimumDoorsPrices = this.calculationDataValue["minimum_doors_prices"];
    this.wallsFactor = this.calculationDataValue["walls_factor"];
    this.windowsFactor = this.calculationDataValue["windows_factor"];
    this.doorsFactor = this.calculationDataValue["doors_factor"];
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    let min, medium, max;

    if (this.areaToPaint === BASE_AREA) {
      [min, medium, max] = this.baseAreaPrices;
    } else {
      [min, medium, max] = this.specificAreaPrices;
    }

    this.lowEstimate = Math.round(min);
    this.averageEstimate = Math.round(medium);
    this.highEstimate = Math.round(max);
  }

  toggleBaseOrSpecificArea() {
    if (
      this.insidePaintingFormTarget.elements.painting_rooms_or_surface.checked
    ) {
      this.areaToPaint = BASE_AREA;
      this.insideBaseAreaBlockTarget.classList.remove("d-none");
      this.insideSpecificAreaBlockTarget.classList.add("d-none");
      this.paintingRoomsOrSurfaceTitleTarget.innerHTML =
        this.roomOrSurfaceTranslationsValue.fullRooms;
    } else {
      this.areaToPaint = SPECIFIC_AREA;
      this.insideBaseAreaBlockTarget.classList.add("d-none");
      this.insideSpecificAreaBlockTarget.classList.remove("d-none");
      this.paintingRoomsOrSurfaceTitleTarget.innerHTML =
        this.roomOrSurfaceTranslationsValue.exactArea;
    }

    this.calculateData();
  }

  /**
   *
   * @returns {number[]}
   */
  get baseAreaPrices() {
    let [min, medium, max] = [0, 0, 0];

    min += this.wallsPrices.min;
    medium += this.wallsPrices.medium;
    max += this.wallsPrices.max;

    min += this.ceilingsPrices.min;
    medium += this.ceilingsPrices.medium;
    max += this.ceilingsPrices.max;

    min += this.floorsPrices.min;
    medium += this.floorsPrices.medium;
    max += this.floorsPrices.max;

    min += this.windowsPrices.min;
    medium += this.windowsPrices.medium;
    max += this.windowsPrices.max;

    min += this.doorsPrices.min;
    medium += this.doorsPrices.medium;
    max += this.doorsPrices.max;

    return [min, medium, max];
  }

  /**
   *
   * @returns {number[]}
   */
  get specificAreaPrices() {
    let [min, medium, max] = this.insideSpecificAreaPrices;

    min *= this.inputArea;
    medium *= this.inputArea;
    max *= this.inputArea;

    return [min, medium, max];
  }

  /**
   *
   * @returns {number}
   */
  get inputArea() {
    if (this.areaToPaint === BASE_AREA) {
      return parseInt(
        this.insidePaintingFormTarget.elements.painting_inside_base_area.value,
      );
    }

    return parseInt(
      this.insidePaintingFormTarget.elements.painting_inside_specific_area
        .value,
    );
  }

  /**
   *
   * @returns {{min: number, max: number, medium: number}}
   */
  get wallsPrices() {
    let [min, medium, max] = [0, 0, 0];

    if (this.wallsTarget.checked) {
      [min, medium, max] = this.insideBaseAreaPrices;
      min *= this.calculationDataValue.walls_factor * this.inputArea;
      medium *= this.calculationDataValue.walls_factor * this.inputArea;
      max *= this.calculationDataValue.walls_factor * this.inputArea;
    }

    return { min, medium, max };
  }

  /**
   *
   * @returns {{min: number, max: number, medium: number}}
   */
  get ceilingsPrices() {
    let [min, medium, max] = [0, 0, 0];

    if (this.ceilingsTarget.checked) {
      [min, medium, max] = this.insideBaseAreaPrices;
      min *= this.inputArea;
      medium *= this.inputArea;
      max *= this.inputArea;
    }

    return { min, medium, max };
  }

  /**
   *
   * @returns {{min: number, max: number, medium: number}}
   */
  get floorsPrices() {
    let [min, medium, max] = [0, 0, 0];

    if (this.floorsTarget.checked) {
      [min, medium, max] = this.insideBaseAreaPrices;
      min *= this.inputArea;
      medium *= this.inputArea;
      max *= this.inputArea;
    }

    return { min, medium, max };
  }

  /**
   *
   * @returns {{min: number, max: number, medium: number}}
   */
  get windowsPrices() {
    let [min, medium, max] = [0, 0, 0];

    if (this.windowsTarget.checked) {
      [min, medium, max] = this.insideBaseAreaPrices;
      const [minMin, minMedium, minMax] = this.minimumWindowsPrices;

      min *= this.windowsFactor * this.inputArea;
      medium *= this.windowsFactor * this.inputArea;
      max *= this.windowsFactor * this.inputArea;

      min = Math.max(min, minMin);
      medium = Math.max(medium, minMedium);
      max = Math.max(max, minMax);
    }

    return { min, medium, max };
  }

  /**
   *
   * @returns {{min: number, max: number, medium: number}}
   */
  get doorsPrices() {
    let [min, medium, max] = [0, 0, 0];

    if (this.doorsTarget.checked) {
      [min, medium, max] = this.insideBaseAreaPrices;
      const [minMin, minMedium, minMax] = this.minimumDoorsPrices;

      min *= this.doorsFactor * this.inputArea;
      medium *= this.doorsFactor * this.inputArea;
      max *= this.doorsFactor * this.inputArea;

      min = Math.max(min, minMin);
      medium = Math.max(medium, minMedium);
      max = Math.max(max, minMax);
    }

    return { min, medium, max };
  }
}
