import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = [
    "parquet",
    "laminate",
    "vinylOrLinoleum",
    "carpet",
    "willTradesmanProvideFlooring",
    "floorSurface",
    "skirtingBoards",
    "floating",
    "gluedDown",
    "layingMethodDoNotKnow",
    "layingMethodContainer",
    "noImpact",
    "partialImpact",
    "entireImpact",
  ];

  connect() {
    super.connect();

    this.flooringTargers = [
      this.parquetTarget,
      this.laminateTarget,
      this.vinylOrLinoleumTarget,
      this.carpetTarget,
    ];

    this.layingMethodTargets = [
      this.floatingTarget,
      this.gluedDownTarget,
      this.layingMethodDoNotKnowTarget,
    ];

    this.skirtingBoardImpactTargets = [
      this.noImpactTarget,
      this.partialImpactTarget,
      this.entireImpactTarget,
    ];

    this.calculateData();
    initRangeInputs();
    initChipCheckboxes();
  }

  /**
   * @returns {
   *   {
   *    "parquet": {"low": number, "medium": number, "high": number},
   *    "laminate": {"low": number, "medium": number, "high": number},
   *    "vinyl_or_linoleum": {"low": number, "medium": number, "high": number},
   *    "carpet": {"low": number, "medium": number, "high": number}
   *   }
   * }
   */
  get prices() {
    if (this.checkedLayingMethod === "floating")
      return this.calculationDataValue.prices_based_on_laying_type.floating;
    if (this.checkedLayingMethod === "glued_down")
      return this.calculationDataValue.prices_based_on_laying_type.glued_down;

    return this.calculationDataValue.prices;
  }

  /**
   *
   * @returns {
   *   {
   *    "parquet": {"low": number, "medium": number, "high": number},
   *    "laminate": {"low": number, "medium": number, "high": number},
   *    "vinyl_or_linoleum": {"low": number, "medium": number, "high": number},
   *    "carpet": {"low": number, "medium": number, "high": number}
   *   }
   * }
   */
  get providedFlooringPrices() {
    return this.calculationDataValue.prices_with_provided_flooring;
  }

  /**
   *
   * @returns {{"low":number,"medium":number,"high":number}} config/initializers/constants.rb:59
   */
  get skirtingBoardPrices() {
    return this.calculationDataValue.skirting_board_prices;
  }

  /**
   *
   * @returns {'parquet'|'laminate'|'vinyl_or_linoleum'|'carpet'}
   */
  get checkedFlooring() {
    for (let i = 0; i < this.flooringTargers.length; i += 1) {
      if (this.flooringTargers[i].checked) {
        return this.flooringTargers[i].value;
      }
    }

    throw Error("No flooring checked");
  }

  /**
   *
   * @returns {'floating'|'glued_down'|'do_not_know'}
   */
  get checkedLayingMethod() {
    for (let i = 0; i < this.layingMethodTargets.length; i += 1) {
      if (this.layingMethodTargets[i].checked) {
        return this.layingMethodTargets[i].value;
      }
    }

    throw Error("No laying method checked");
  }

  /**
   *
   * @returns {number}
   */
  get surface() {
    return parseInt(this.floorSurfaceTarget.value);
  }

  /**
   *
   * @returns {{"low": number, "medium": number, "high": number}}
   */
  get currentFlooringPrices() {
    return this.prices[this.checkedFlooring];
  }

  handleFlooringWithLayoutType() {
    this.layingMethodContainerTarget.classList.remove("d-none");
  }

  handleFlooringWithoutLayoutType() {
    this.layingMethodDoNotKnowTarget.checked = true;
    this.layingMethodContainerTarget.classList.add("d-none");
  }

  /**
   *
   * @param {'low'|'medium'|'high'} range
   * @returns {Number}
   */
  additionalSkirtingPrice(range) {
    const checkedSkirtingBoardImpact = this.checkedSkirtingBoardImpact();
    if (checkedSkirtingBoardImpact === "no_impact") return 0;

    const price = this.skirtingBoardPrices[range];
    let length = 0;

    if (checkedSkirtingBoardImpact === "partial_impact")
      length = (this.surface * 0.75) / 2;
    if (checkedSkirtingBoardImpact === "entire_impact")
      length = this.surface * 0.75;

    return price * length;
  }

  /**
   *
   * @returns {'no_impact'|'partial_impact'|'entire_impact'}
   */
  checkedSkirtingBoardImpact() {
    for (let i = 0; i < this.skirtingBoardImpactTargets.length; i += 1) {
      if (this.skirtingBoardImpactTargets[i].checked) {
        return this.skirtingBoardImpactTargets[i].value;
      }
    }
  }

  /**
   *
   * @returns {number}
   */
  providedFlooringPrice(range) {
    if (this.willTradesmanProvideFlooringTarget.checked) {
      return (
        this.providedFlooringPrices[this.checkedFlooring][range] * this.surface
      );
    }

    return 0;
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    this.lowEstimate = this._calculateRangeEstimate("low");
    this.averageEstimate = this._calculateRangeEstimate("medium");
    this.highEstimate = this._calculateRangeEstimate("high");
  }

  /**
   *
   * @param {'low'|'medium'|'high'} range
   * @private
   * @return {number}
   */
  _calculateRangeEstimate(range) {
    return Math.round(
      this.currentFlooringPrices[range] * this.surface +
        this.providedFlooringPrice(range) +
        this.additionalSkirtingPrice(range),
    );
  }
}
