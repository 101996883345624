$("#price-estimator").on("click", ".show-details-all", function (e) {
  $(".show-details:visible a").click();
  e.preventDefault();
});

$("#price-estimator").on("click", ".hide-details-all", function (e) {
  $(".hide-details:visible a").click();
  e.preventDefault();
});

$("#price-estimator").on("click", ".show-details a", function (e) {
  $(this).closest("div").hide().next().stop().toggle();
  e.preventDefault();
});

$("#price-estimator").on("click", ".hide-details a", function (e) {
  $(this).closest(".details-quotes").stop().toggle().prev().show();
  e.preventDefault();
});
