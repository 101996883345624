import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option", "block"];

  initialize() {
    this.optionTargets[0].checked = true;
    this.blockTargets[0].classList.remove("d-none");
    this.blockTargets[0].classList.add("block");
  }

  toggle(e) {
    const blockTargetIndex = parseInt(e.target.value);

    for (let i = 0; i < this.optionTargets.length; i += 1) {
      if (i === blockTargetIndex) {
        this.blockTargets[i].classList.remove("d-none");
      } else {
        this.blockTargets[i].classList.add("d-none");
      }
    }
  }
}
