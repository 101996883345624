import { Controller } from "stimulus";
import * as Sentry from "@sentry/browser";
import { Fetcher } from "../fetcher";

export default class extends Controller {
  static targets = [
    "cta",
    "failedAttempt",
    "number1",
    "number2",
    "number3",
    "number4",
    "number5",
    "number6",
    "status",
    "suspendAttempts",
  ];

  static values = {
    attemptsUrl: String,
    authenticateUrl: String,
    userTrackingParams: Object,
  };

  connect() {
    this.fetcher = new Fetcher();
  }

  validateAttempt(event) {
    event.preventDefault();

    const url = this.attemptsUrlValue;

    this.removeMagicCodeErrors(
      this.failedAttemptTarget,
      this.suspendAttemptsTarget,
    );

    if (this.magicCodeReady) {
      this.fetcher
        .request(url, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            magic_code: this.magicCode,
          }),
        })
        .then((response) => {
          if (response.permit) {
            this.removeMagicCodeErrors(
              this.failedAttemptTarget,
              this.suspendAttemptsTarget,
            );
            this.checkingStatus();
            this.authenticate();
          } else if (response.temporary_ban) {
            this.removeMagicCodeErrors(this.failedAttemptTarget);
            this.addMagicCodeError(this.suspendAttemptsTarget);
          } else if (response.wrong_code) {
            this.removeMagicCodeErrors(this.suspendAttemptsTarget);
            this.addMagicCodeError(this.failedAttemptTarget);
          } else if (response.location) {
            window.location.href = response.location;
          } else {
            Sentry.captureMessage(
              `MagicCodeFormController: Unknown response: ${response}`,
            );
          }
        });
    }
  }

  authenticate() {
    const url = this.authenticateUrlValue;

    this.fetcher
      .request(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          magic_code: this.magicCode,
          confirm: this.userTrackingParamsValue.confirm,
          pageflow: this.userTrackingParamsValue.pageflow,
        }),
      })
      .then((response) => {
        window.location = response.redirect_url;
      });
  }

  checkingStatus() {
    this.statusTarget.classList.toggle("d-flex");
  }

  get magicCode() {
    return (
      this.number1Target.value +
      this.number2Target.value +
      this.number3Target.value +
      this.number4Target.value +
      this.number5Target.value +
      this.number6Target.value
    );
  }

  get magicCodeReady() {
    return (
      this.number1Target.value &&
      this.number2Target.value &&
      this.number3Target.value &&
      this.number4Target.value &&
      this.number5Target.value &&
      this.number6Target.value
    );
  }

  addMagicCodeError(target) {
    target.classList.remove("d-none");
    target.classList.add("d-flex");
  }

  removeMagicCodeErrors(...targets) {
    targets.forEach((target) => {
      target.classList.remove("d-flex");
      target.classList.add("d-none");
    });
  }
}
