import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "residentsChart",
    "householdsChart",
    "vacancyChart",
    "housingChart",
    "densityChart",
    "ageChart",
    "socialChart",
    "foreignersChart",
    "birthChart",
    "mortalityChart",
    "economicChart",
    "surfaceChart",
    "politicsChart",
  ];

  connect() {
    this.mainColor = "rgb(48,117,181)";
    this.secondaryColor = "rgb(29,77,124)";
    this.tertiaryColor = "rgb(155, 184, 215)";
    this.supportColors = [
      "rgb(105,153,204)",
      "#f7fbff",
      "#deebf7",
      "#c6dbef",
      "#9ecae1",
      "#6baed6",
      "#4292c6",
      "#2171b5",
      "#08519c",
      "#08306b",
    ];
    import(/* webpackChunkName: "chart.js" */ "chart.js").then((module) => {
      import(
        /* webpackChunkName: "chartjs-plugin-piechart-outlabels" */ "chartjs-plugin-piechart-outlabels"
      ).then(() => {
        this.addBarChart(
          module,
          this.residentsChartTarget,
          "parseComparisonBarChart",
        );
        this.addBarChart(
          module,
          this.householdsChartTarget,
          "parseSimpleBarChart",
        );
        this.addBarChart(
          module,
          this.vacancyChartTarget,
          "parseSimpleBarChart",
        );
        this.addBarChart(
          module,
          this.housingChartTarget,
          "parseSimpleBarChart",
        );

        this.addBarChart(
          module,
          this.densityChartTarget,
          "parseSimpleBarChart",
        );
        this.addPieChart(module, this.ageChartTarget, "parsePieChart");
        this.addBarChart(module, this.socialChartTarget, "parseSimpleBarChart");
        this.addBarChart(
          module,
          this.foreignersChartTarget,
          "parseSimpleBarChart",
        );
        this.addBarChart(module, this.birthChartTarget, "parseSimpleBarChart");
        this.addBarChart(
          module,
          this.mortalityChartTarget,
          "parseSimpleBarChart",
        );

        this.addNestedPieChart(
          module,
          this.economicChartTarget,
          "parseNestedPieChart",
        );

        this.addPieChart(module, this.surfaceChartTarget, "parsePieChart");
        this.addPieChart(module, this.politicsChartTarget, "parsePieChart");
      });
    });
  }

  addBarChart(chartModule, target, parseMethod) {
    new chartModule.default(
      target,
      this.barChartConfig(
        target,
        this[parseMethod](target, JSON.parse(target.dataset.chart)),
      ),
    );
  }

  addPieChart(chartModule, target, parseMethod) {
    new chartModule.default(
      target,
      this.pieChartConfig(
        target,
        this[parseMethod](target, JSON.parse(target.dataset.chart)),
      ),
    );
  }

  addNestedPieChart(chartModule, target, parseMethod) {
    new chartModule.default(
      target,
      this.nestedPieChartConfig(
        target,
        this[parseMethod](target, JSON.parse(target.dataset.chart)),
      ),
    );
  }

  barChartConfig(target, data) {
    return {
      type: "bar",
      data: data,
      options: {
        layout: {
          padding: 10,
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            title: () => null,
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: target.dataset.title,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  }

  nestedPieChartConfig(target, data) {
    return {
      type: "doughnut",
      data: data,
      options: {
        layout: {
          padding: 10,
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
        },
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: true,
          text: target.dataset.title,
        },
        plugins: {
          outlabels: {
            display: false,
          },
        },
      },
    };
  }

  pieChartConfig(target, data) {
    return {
      type: "outlabeledDoughnut",
      data: data,
      options: {
        layout: {
          padding: 10,
        },
        responsive: true,
        maintainAspectRatio: false,
        zoomOutPercentage: 45,
        tooltips: {
          enabled: true,
        },
        title: {
          display: true,
          text: target.dataset.title,
        },
        plugins: {
          legend: false,
          outlabels: {
            backgroundColor: "white",
            color: "black",
            text: "%l",
            stretch: 15,
            padding: 1,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 18,
            },
          },
        },
      },
    };
  }

  parsePieChart(target, json) {
    return {
      labels: JSON.parse(target.dataset.labels),
      datasets: [
        {
          backgroundColor: [
            this.mainColor,
            this.secondaryColor,
            this.tertiaryColor,
            ...this.supportColors,
          ],
          data: json,
        },
      ],
    };
  }

  parseNestedPieChart(target, json) {
    return {
      labels: JSON.parse(target.dataset.labels),
      datasets: [
        {
          backgroundColor: [
            this.mainColor,
            this.secondaryColor,
            this.tertiaryColor,
            this.mainColor,
            this.secondaryColor,
            this.tertiaryColor,
          ],
          data: [
            0,
            0,
            0,
            json.companies.primary,
            json.companies.secondary,
            json.companies.tertiary,
          ],
        },
        {
          backgroundColor: [
            this.mainColor,
            this.secondaryColor,
            this.tertiaryColor,
          ],
          data: [
            json.employees.primary,
            json.employees.secondary,
            json.employees.tertiary,
          ],
        },
      ],
    };
  }

  parseComparisonBarChart(_, json) {
    return {
      labels: [
        [json.previous.year, ""],
        [json.current.year, ""],
      ], // keep labels aligned
      datasets: [
        {
          data: [json.previous.value, json.current.value],
          backgroundColor: [this.mainColor, this.secondaryColor],
        },
      ],
    };
  }

  parseSimpleBarChart(target, json) {
    return {
      labels: JSON.parse(target.dataset.labels),
      datasets: [
        {
          data: [json.commune, json.global],
          backgroundColor: [this.mainColor, this.secondaryColor],
        },
      ],
    };
  }
}
