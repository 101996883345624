import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = [
    "sandingAndSealing",
    "grindingAndOiling",
    "cleaningAndOiling",
    "floorSurface",
  ];

  connect() {
    super.connect();

    this.actionTargets = [
      this.sandingAndSealingTarget,
      this.grindingAndOilingTarget,
      this.cleaningAndOilingTarget,
    ];

    this.calculateData();
    initRangeInputs();
    initChipCheckboxes();
  }

  /**
   *
   * @returns {'sanding_and_sealing'|'grinding_and_oiling'|'cleaning_and_oiling'}
   */
  get checkedAction() {
    for (let i = 0; i < this.actionTargets.length; i += 1) {
      if (this.actionTargets[i].checked) {
        return this.actionTargets[i].value;
      }
    }

    throw Error("No flooring checked");
  }

  /**
   * @returns {
   *   {
   *    "sanding_and_sealing": {"low": number, "medium": number, "high": number},
   *    "grinding_and_oiling": {"low": number, "medium": number, "high": number},
   *    "cleaning_and_oiling": {"low": number, "medium": number, "high": number}
   *   }
   * }
   */
  get prices() {
    return this.calculationDataValue.prices;
  }

  /**
   *
   * @returns {{"low": number, "medium": number, "high": number}}
   */
  get currentActionPrices() {
    return this.prices[this.checkedAction];
  }

  /**
   *
   * @returns {number}
   */
  get surface() {
    return parseInt(this.floorSurfaceTarget.value);
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    this.lowEstimate = Math.round(this.currentActionPrices.low * this.surface);
    this.averageEstimate = Math.round(
      this.currentActionPrices.medium * this.surface,
    );
    this.highEstimate = Math.round(
      this.currentActionPrices.high * this.surface,
    );
  }
}
