import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { template } from "lodash-es";

export default class extends Controller {
  static targets = ["messageContainer", "input"];
  static values = {
    errorMessage: String,
    successMessage: String,
    url: String,
  };

  messageContainerTarget: HTMLElement;
  inputTarget: HTMLInputElement;
  errorMessageValue: string;
  successMessageValue: string;
  successTemplate: (arg: object) => string;
  urlValue: string;
  fetcher: Fetcher;

  connect() {
    this.fetcher = new Fetcher();
    this.successTemplate = template(this.successMessageValue);
  }

  async click(event: any) {
    if (event.target.value == "" || event.target.value.includes("_")) {
      this.reset();
      return;
    }

    const result = await this.getName(event.target.value);

    if (!result) {
      this.markAsInvalid();
    } else {
      this.markAsValid(result);
    }
  }

  private reset() {
    // this.inputTarget.setCustomValidity("");
    this.inputTarget.classList.remove("is-invalid");
    this.messageContainerTarget.innerHTML = "";
  }

  private markAsInvalid() {
    // this.inputTarget.setCustomValidity("error");
    this.inputTarget.classList.add("is-invalid");
    this.messageContainerTarget.innerHTML = this.errorMessageValue;
  }

  private markAsValid(name: string) {
    this.inputTarget.setCustomValidity("");
    this.inputTarget.classList.remove("is-invalid");
    this.messageContainerTarget.innerHTML = this.successTemplate({
      company_name: name,
    });
  }

  private async getName(uid: string): Promise<string> {
    return this.fetcher
      .request(this.urlValue + `?uid=${uid}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response["company_name"]);
  }
}
