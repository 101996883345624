import { Controller } from "stimulus";
import { notification } from "../components/notification";

export default class extends Controller {
  static targets = ["modal", "submit"];

  showModal(event) {
    this.submitTarget.setAttribute("href", event.currentTarget.dataset.url);
    $(this.modalTarget).modal("show");
    event.preventDefault();
  }

  onReportSuccess(event) {
    $(this.modalTarget).modal("hide");

    const json = event.detail[0];

    document.querySelector(`#rating-${json.id} .vote-box`).innerHTML =
      json.content;

    notification(json.message, "success");
  }
}
