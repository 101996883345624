import { Controller } from "stimulus";

const MULTIPLIER_FOR_CREATE_JOB = 2.4 * 1.2;
const MULTIPLIER_FOR_CREATE_INVITATION = 2.4 * 0.3;
const MULTIPLIER_FOR_CONFIRM_CONTACT_REQUEST = 2.4;

export default class extends Controller {
  static values = {
    category: String,
    action: String,
    name: String,
    amount: Number,
    sendOnLoad: Boolean, // defaults to false
  };

  initialize() {
    if (this.sendOnLoadValue) {
      this.sendEvent();
    }
  }

  calculateJobCost() {
    if (!this.hasAmountValue) return;

    switch (this.actionValue) {
      case "create-job":
        return this.amountValue * MULTIPLIER_FOR_CREATE_JOB;
      case "create-invitation":
        return this.amountValue * MULTIPLIER_FOR_CREATE_INVITATION;
      case "confirm-contact-request":
        return this.amountValue * MULTIPLIER_FOR_CONFIRM_CONTACT_REQUEST;
      default:
        return this.amountValue;
    }
  }

  sendEvent() {
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        this.categoryValue,
        this.actionValue,
        this.nameValue,
        this.calculateJobCost(),
      ]);
    }
  }
}
