import { Controller } from "stimulus";
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    const latitude = parseFloat(this.data.get("latitude"));
    const longitude = parseFloat(this.data.get("longitude"));
    const icon = this.data.get("marker-icon");
    const shadow = this.data.get("marker-shadow");
    var center = [latitude, longitude];
    var map = L.map(this.containerTarget, {
      gestureHandling: true,
      gestureHandlingOptions: {
        text: {
          touch: "Hey bro, use two fingers to move the map",
          scroll: "Hey bro, use ctrl + scroll to zoom the map",
          scrollMac: "Hey bro, use \u2318 + scroll to zoom the map",
        },
      },
    }).setView(center, 12);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 25,
    }).addTo(map);

    const markerIcon = L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: icon,
      shadowUrl: shadow,
    });
    L.marker(center, { icon: markerIcon }).addTo(map);
  }
}
