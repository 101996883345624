// Jobs#New, used on quotes - tradesman view as well
$(function () {
  // Upload files
  var $fileInputItemTemplate = $(".file-input-item").clone();
  var $fileInputsContainer = $(".file-input-items");

  $("form").on("click", ".delete-file-item", function (e) {
    e.preventDefault();
    var $items = $fileInputsContainer.children();
    if ($items.length > 1) {
      $(this).closest(".file-input-item").remove();
    } else {
      $items.first().find("input").val(null);
    }
  });

  $("form").on("click", ".add-file-item", function (e) {
    e.preventDefault();
    $fileInputsContainer.append($fileInputItemTemplate.clone());
  });
});
