import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";

export default class extends Controller {
  static targets = ["container", "answer", "button"];

  static values = {
    className: String,
    jobId: String,
    submissionId: String,
    url: String,
    validationMessage: String,
  };

  private answerTarget: HTMLInputElement;
  private answerTargets: HTMLInputElement[];
  private buttonTargets: HTMLButtonElement[];
  private containerTarget: HTMLElement;

  private classNameValue: string;
  private jobIdValue: string;
  private submissionIdValue: string;
  private urlValue: string;
  private validationMessageValue: string;

  submit(body): void {
    this.disableButtons();

    const fetcher = new Fetcher();
    fetcher
      .request(this.urlValue, {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(body),
      })
      .then((response) => this.renderPage(response))
      .catch(() => {
        serverErrorNotification();
        this.enableButtons();
      });
  }

  validateJobId(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.answerTarget.value = target.value.replace(/[^0-9]/g, "");
  }

  submitJobId(): void {
    this.jobIdValue = this.answerTarget.value;
    this.submit({
      job_id: this.jobIdValue,
    });
  }

  submitAnswer(): void {
    const answer = this.answerTarget.value;
    if (this.answerInValid()) {
      return;
    }

    this.submit({
      answer: answer,
      class_name: this.classNameValue,
      job_id: this.jobIdValue,
      submission_id: this.submissionIdValue,
    });
  }

  submitSelectedAnswer(event): void {
    const answer = event.currentTarget.value;
    this.submit({
      answer: answer,
      class_name: this.classNameValue,
      job_id: this.jobIdValue,
      submission_id: this.submissionIdValue,
    });
  }

  submitAllTargets(): void {
    const answer = [];

    this.answerTargets.forEach((target) => {
      if (target.value === "") {
        return;
      }
      answer.push(target.value);
    });

    this.submit({
      answer: JSON.stringify(answer),
      class_name: this.classNameValue,
      job_id: this.jobIdValue,
      submission_id: this.submissionIdValue,
    });
  }

  renderPage(response): void {
    if (response.url) {
      this.urlValue = response.url;
    }

    if (response.template) {
      this.containerTarget.innerHTML = response.template;
    }

    if (response.submissionId) {
      this.submissionIdValue = response.submissionId;
    }

    if (response.className) {
      this.classNameValue = response.className;
    }

    if (response.jobId) {
      this.jobIdValue = response.jobId;
    }
  }

  answerInValid(): boolean {
    if (!this.answerTarget.checkValidity()) {
      this.answerTarget.setCustomValidity(this.validationMessageValue);
      this.answerTarget.reportValidity();
      return true;
    }
    return false;
  }

  enableButtons(): void {
    this.buttonTargets.forEach((button) => {
      button.disabled = false;
    });
  }

  disableButtons(): void {
    this.buttonTargets.forEach((button) => {
      button.disabled = true;
    });
  }

  answerTargetClick(): void {
    this.answerTarget.setCustomValidity("");
  }
}
