import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["actions"];

  refreshView(event) {
    const json = event.detail[0];
    this.actionsTarget.innerHTML = json.content;
  }
}
