import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["placeholder", "content"];

  initialize() {
    window.onload = () => {
      this.contentTarget.classList.remove("opacity-0");
      this.placeholderTarget.remove();
    };
  }
}
