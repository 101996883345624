import { Controller } from "stimulus";
import { cloneDeep } from "lodash-es";

export default class extends Controller {
  static targets = [
    "metaLowEstimate",
    "metaAverageEstimate",
    "metaHighEstimate",
  ];

  static values = { calculationDataObject: Object };

  static showCalculationData = false;

  connect() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)

    // wee deep copy calculation data object & delete it from html so it's not accessible with inspect element
    this.calculationDataValue = cloneDeep(this.calculationDataObjectValue);
    this.hideCalculationDataFromHTML();
  }

  get averageEstimate() {
    return this._averageEstimate;
  }

  get lowEstimate() {
    return this._lowEstimate;
  }

  get highEstimate() {
    return this._highEstimate;
  }

  // TODO: check if we can find more "stimulus" way to handle multi-controller & meta widget logic
  set averageEstimate(value) {
    this._averageEstimate = value;
    document.getElementById("average_estimate").value = value;

    document
      .querySelectorAll(".cost-estimator-average-estimate")
      .forEach((elem) => (elem.innerHTML = `${Math.round(value)}.-`));
  }

  set lowEstimate(value) {
    this._lowEstimate = value;
    document.getElementById("low_estimate").value = value;

    document
      .querySelectorAll(".cost-estimator-low-estimate")
      .forEach((elem) => (elem.innerHTML = `${Math.round(value)}.-`));
  }

  set highEstimate(value) {
    this._highEstimate = value;
    document.getElementById("high_estimate").value = `${Math.round(value)}.-`;

    document
      .querySelectorAll(".cost-estimator-high-estimate")
      .forEach((elem) => (elem.innerHTML = `${Math.round(value)}.-`));
  }

  /* remove price info from html so it can not be viewed with inspecting element */
  hideCalculationDataFromHTML() {
    const element = document.querySelector("#ce-root");

    Object.keys(element.dataset).forEach((dataKey) => {
      if (dataKey !== "controller") delete this.element.dataset[dataKey];
    });
  }

  showCalculation() {
    document
      .querySelectorAll(".cost-estimator-calculation-button")
      .forEach((elem) => elem.classList.add("d-none"));
    document
      .querySelectorAll(".cost-estimator-calculation-results")
      .forEach((elem) => elem.classList.remove("d-none"));
    document
      .querySelectorAll(".cost-estimator-recalculation-button")
      .forEach((elem) => elem.classList.remove("d-none"));
  }

  hideCalculation() {
    document
      .querySelectorAll(".cost-estimator-calculation-button")
      .forEach((elem) => elem.classList.remove("d-none"));
    document
      .querySelectorAll(".cost-estimator-calculation-results")
      .forEach((elem) => elem.classList.add("d-none"));
    document
      .querySelectorAll(".cost-estimator-recalculation-button")
      .forEach((elem) => elem.classList.add("d-none"));
  }
}
