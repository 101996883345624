import { Controller } from "stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
import { Fetcher } from "../fetcher";

export default class extends Controller {
  static values = {
    event_name: String,
    locale: String,
    url: String,
  };

  initialize() {
    this.fetcher = new Fetcher();
  }

  connect() {
    new autoComplete({
      data: {
        src: async (query) =>
          await this.fetcher.request(
            `${this.urlValue}?term=${encodeURIComponent(query)}${this.localeParam}`,
            { method: "get" },
          ),
        key: ["label"],
        cache: false,
      },
      threshold: 1,
      debounce: 300,
      resultsList: {
        id: "autoComplete_results_list",
        class: "ps-0",
        maxResults: 10,
      },
      resultItem: {
        class: "autoComplete_result",
        element: (item, data) => (item.innerText = data.value.label),
        highlight: true,
      },
      searchEngine: (query, record) => record,
      events: {
        input: { selection: (event) => this.redirectToTag(event) },
      },
    });
  }

  redirectToTag(event) {
    window.location.href = event.detail.selection.value.path;
  }

  get localeParam() {
    return this.localeValue ? `&locale=${this.localeValue}` : "";
  }
}
