import { Controller } from "stimulus";
import { template } from "lodash-es";

interface Categories {
  id: number;
  name: string;
}

export default class extends Controller {
  restrictedIdsValue: Array<number>;
  selectedIdsValue: Array<number>;
  categoriesValue: Array<Categories>;
  warningTemplateTarget: HTMLElement;
  warningTarget: HTMLElement;
  warningTemplate: (arg: object) => string;

  static values = {
    restrictedIds: Array,
    selectedIds: Array,
    categories: Array,
  };

  static targets = ["warningTemplate", "warning"];

  connect(): void {
    this.warningTemplate = template(this.warningTemplateTarget.innerHTML);
    this.checkRestrictedCategories();
  }

  categorySelected(event: Event): void {
    const element = event.target as HTMLInputElement;

    if (element.checked) {
      this.selectedIdsValue = [
        ...this.selectedIdsValue,
        parseInt(element.value),
      ];
    } else {
      this.selectedIdsValue = this.selectedIdsValue.filter(
        (id) => id !== parseInt(element.value),
      );
    }

    this.checkRestrictedCategories();
  }

  checkRestrictedCategories(): void {
    const selectedRestrictedIds = this.restrictedIdsValue.filter((id) =>
      this.selectedIdsValue.includes(id),
    );

    if (selectedRestrictedIds.length > 0) {
      const names = selectedRestrictedIds
        .map((id) => {
          const category = this.categoriesValue.find((c) => c.id === id);
          return category.name;
        })
        .sort()
        .join(", ");

      this.warningTemplateTarget.innerHTML = this.warningTemplate({
        categories: names,
      });
      this.warningTarget.classList.remove("d-none");
    } else {
      this.warningTarget.classList.add("d-none");
    }
  }
}
