import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  static values = {
    path: String,
    resendButton: String,
  };

  connect() {
    let seconds = 119;
    const id = setInterval(() => {
      if (seconds === 0) {
        this.renderResendButton();
        clearInterval(id);
        return;
      }

      this.buttonTarget.innerText = `${this.formatNumber(Math.floor(seconds / 60))}:${this.formatNumber(seconds % 60)}`;
      seconds -= 1;
    }, 1000);
  }

  formatNumber(number) {
    return Math.floor(number).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  renderResendButton() {
    this.buttonTarget.outerHTML = `<a href='${this.pathValue}' class='btn btn-lg btn-primary w-100'>${this.resendButtonValue}</a>`;
  }
}
