$(document).ready(function () {
  $(".reject-proposal, .accept-proposal, .confirm-contact-request").colorbox({
    width: "40%",
  });

  $("a.end-bidding").colorbox({
    inline: true,
    width: "40%",
  });

  $("a.reject-quotes").colorbox({
    inline: true,
    width: "40%",
  });
});
