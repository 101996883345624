import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";

export default class extends Controller {
  static targets = ["file", "field", "form"];

  connect() {
    this.aspectRatio = parseFloat(this.data.get("aspect"));
    this.file = null;
  }

  attachFile(event) {
    event.preventDefault();
    this.fileTarget.click();
  }

  fileReady(url) {
    const customEvent = new CustomEvent("file-ready", {
      bubbles: true,
      cancelable: false,
      detail: {
        aspectRatio: this.aspectRatio,
        url: url,
        type: this.file.type,
      },
    });

    this.fileTarget.dispatchEvent(customEvent);
  }

  fileAttached(event) {
    event.preventDefault();

    let reader;
    const files = this.fileTarget.files;

    if (files && files.length > 0) {
      this.file = files[0];
      if (URL) {
        this.fileReady(URL.createObjectURL(this.file));
      } else if (FileReader) {
        reader = new FileReader();
        reader.onload = function () {
          this.fileReady(reader.result);
        };
        reader.readAsDataURL(this.file);
      }
    }
  }

  modalClosed() {
    // cleanup file
    this.file = null;
  }

  uploadFile(event) {
    if (!this.file) {
      return;
    }

    const formData = new FormData();
    formData.append("company_image[photo]", event.detail.blob, this.file.name);

    this.fieldTargets.forEach((item) =>
      formData.append(
        `company_image[${item.getAttribute("name")}]`,
        item.value,
      ),
    );

    const fetcher = new Fetcher();
    fetcher
      .request(this.formTarget.getAttribute("action"), {
        method: "post",
        body: formData,
      })
      .then((res) => {
        window.location = res.redirect_url;
        window.location.reload();
      })
      .catch(() => {
        serverErrorNotification();
      });
  }
}
