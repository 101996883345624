import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "handler", "image"];

  connect() {
    this.cropper = null;
    this.fileType = null;
    const self = this;

    import(/* webpackChunkName: "cropperjs" */ "cropperjs").then((module) => {
      $(this.modalTarget)
        .on("hidden.bs.modal", function () {
          self.cropper.destroy();
          self.dispatchEvent("modal-closed");
        })
        .on("shown.bs.modal", function () {
          // have to wait for modal to fully open, otherwise it messes up height
          self.cropper = new module.default(self.imageTarget, {
            aspectRatio: self.aspectRatio,
            viewMode: 0,
            autoCropArea: 1,
          });
        });
    });
  }

  confirmUpload(event) {
    const self = this;

    event.currentTarget.classList.add("busy", "disabled");
    event.currentTarget.disabled = true;

    this.cropper
      .getCroppedCanvas({
        fillColor: "#fff",
        maxWidth: 2400,
        maxHeight: 2400,
        imageSmoothingEnabled: false,
      })
      .toBlob(
        function (blob) {
          self.dispatchEvent("upload-confirmed", {
            blob: blob,
          });

          // let's not close this atm
          // as the upload process in synchronous
          // $(self.modalTarget).modal('hide')
        },
        this.fileType,
        0.7,
      );
  }

  dispatchEvent(eventName, eventData = null) {
    const event = new CustomEvent(eventName, {
      bubbles: true,
      cancelable: false,
      detail: eventData,
    });

    this.handlerTargets.forEach((target) => target.dispatchEvent(event));
  }

  showModal(event) {
    this.imageTarget.src = event.detail.url;
    this.fileType = event.detail.type;
    this.aspectRatio = event.detail.aspectRatio;
    $(this.modalTarget).modal("show");
  }

  zoomIn(event) {
    event.preventDefault();
    this.cropper.zoom(0.1);
  }

  zoomOut(event) {
    event.preventDefault();
    this.cropper.zoom(-0.1);
  }

  resetImage(event) {
    event.preventDefault();
    this.cropper.reset();
  }
}
