import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

const BUILD_PATIO = "build_patio";
const LANDSCAPING_PROJECT = "landscaping_project";
const TURFING = "turfing";
const BUILD_DECKING = "build_decking";
const FENCE_INSTALLATION = "fence_installation";

export default class extends CostEstimatorController {
  static targets = [
    "landscapingForm",
    "patioScope",
    "landscapingScope",
    "scopeInput",
    "turfingScope",
    "deckingScope",
    "fencingScope",
  ];

  currentLandscapingAction = null;

  connect() {
    super.connect();

    initRangeInputs();
    initChipCheckboxes();

    this.calculateData();
  }

  get landscapingAction() {
    return this.landscapingFormTarget.elements.landscaping_action.value;
  }

  get scopeValue() {
    return this.landscapingFormTarget.elements.scope.value;
  }

  get isLandscapingActionChanged() {
    return this.currentLandscapingAction !== this.landscapingAction;
  }

  handleScopeTypeChange() {
    if (!this.isLandscapingActionChanged) return;

    switch (this.landscapingAction) {
      case BUILD_PATIO:
        this.scopeInputTarget.innerHTML = this.patioScopeTarget.innerHTML;
        break;
      case LANDSCAPING_PROJECT:
        this.scopeInputTarget.innerHTML = this.landscapingScopeTarget.innerHTML;
        break;
      case TURFING:
        this.scopeInputTarget.innerHTML = this.turfingScopeTarget.innerHTML;
        break;
      case BUILD_DECKING:
        this.scopeInputTarget.innerHTML = this.deckingScopeTarget.innerHTML;
        break;
      case FENCE_INSTALLATION:
        this.scopeInputTarget.innerHTML = this.fencingScopeTarget.innerHTML;
        break;
    }

    this.currentLandscapingAction = this.landscapingAction;
    initRangeInputs();
  }

  calculateData() {
    this.handleScopeTypeChange();
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)

    let { low, medium, high } =
      this.calculationDataValue[this.landscapingAction];

    low *= this.scopeValue;
    medium *= this.scopeValue;
    high *= this.scopeValue;

    this.lowEstimate = Math.round(low);
    this.averageEstimate = Math.round(medium);
    this.highEstimate = Math.round(high);
  }
}
