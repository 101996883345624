import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "sidebarLeft",
    "sidebarRight",
    "user",
    "menu",
    "sidebarIcon",
  ];

  static sidebarOpen = false;

  connect() {
    this.insertSidebarContent();
  }

  toggleLeftMenu() {
    this.sidebarLeftTarget.classList.toggle("left-0");

    // TODO: remove extra check once FR version is fully implemented
    if (this.hasSidebarRightTarget) {
      this.sidebarRightTarget.classList.remove("right-0");
    }

    this.preventBackgroundScrolling();
    this.setSidebar();
  }

  toggleRightMenu() {
    this.sidebarLeftTarget.classList.remove("left-0");

    // TODO: remove extra check once FR version is fully implemented
    if (this.hasSidebarRightTarget) {
      this.sidebarRightTarget.classList.toggle("right-0");
    }

    this.preventBackgroundScrolling();
    this.setSidebar();
  }

  insertSidebarContent() {
    if (this.hasMenuTarget) {
      this.sidebarLeftTarget.insertAdjacentHTML(
        "beforeend",
        this.menuTarget.innerHTML,
      );
    }

    // TODO: remove extra check once FR version is fully implemented
    if (this.hasUserTarget && this.hasSidebarRightTarget) {
      this.sidebarRightTarget.insertAdjacentHTML(
        "beforeend",
        this.userTarget.innerHTML,
      );
    }
  }

  clickOutsideNavAndClose(e) {
    const sidebars = [this.sidebarLeftTarget];

    // TODO: remove extra check once FR version is fully implemented
    if (this.hasSidebarRightTarget) {
      sidebars.push(this.sidebarRightTarget);
    }

    // avoid closing the menu when clicking on an empty space withing the menu
    if (sidebars.includes(e.target)) return;

    // avoid closing the menu when initially trying to open it
    if (this.sidebarIconTargets.includes(e.target)) return;

    if (this.sidebarOpen) {
      this.closeSidebars();
      this.preventBackgroundScrolling();
      this.setSidebar();
    }
  }

  closeSidebars() {
    this.sidebarLeftTarget.classList.remove("left-0");

    // TODO: remove extra check once FR version is fully implemented
    if (this.hasSidebarRightTarget) {
      this.sidebarRightTarget.classList.remove("right-0");
    }
  }

  setSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  preventBackgroundScrolling() {
    document.body.classList.toggle("position-fixed");
    document.body.classList.toggle("w-100");
  }
}
