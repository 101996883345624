import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  private commentTarget: HTMLElement;

  static targets = ["quote", "contact", "comment", "commentForm"];

  connect(): void {
    if (window.location.hash) {
      this.scrollToElementTop(document.querySelector(window.location.hash));
    }
  }

  reject(event): void {
    event.preventDefault();

    const modal = new Modal(document.querySelector("#rejectInvitationModal"));
    modal.show();
  }

  // handles comment cta button click event
  // scroll to limited participation feed or to comment form focusing it
  comment(event): void {
    event.preventDefault();
    if (
      this.commentTarget.getAttribute("href") == ".limited-participation-info"
    ) {
      this.scrollToElementTop(
        document.querySelector(".limited-participation-info"),
      );
    } else if (document.querySelector("#comment_body")) {
      const newCommentForm: HTMLElement =
        document.querySelector("#comment_body");
      this.scrollToElementTop(newCommentForm);
      newCommentForm.focus();
    }
  }

  contact(event): void {
    event.preventDefault();
    const contactInfo = document.querySelector("#contact-info");

    if (contactInfo) {
      this.scrollToElementTop(contactInfo);
    }
  }

  private scrollToElementTop(element, scrollOffset = 10): void {
    if (element) {
      const scrollTop = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: scrollTop - scrollOffset,
        behavior: "smooth",
      });
    }
  }
}
