import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "switzerland",
    "liechtenstein",
    "soleTrader",
    "companyUid",
    "messageContainer",
  ];

  connect() {
    this.soleTraderChange();
  }

  toggle(event) {
    event.preventDefault();

    this.switzerlandTarget.classList.add("hidden");
    this.liechtensteinTarget.classList.add("hidden");

    const active = event.target.dataset.switch;
    this[`${active}Target`].classList.remove("hidden");
    this.soleTraderTarget.nextSibling.innerHTML = this.data.get(
      `label-${active}`,
    );
    this.soleTraderTarget.checked = false;
    this.soleTraderChange();
    this.resetInputField();
  }

  soleTraderChange(event) {
    if (this.soleTraderTarget.checked) {
      this.companyUidTargets.forEach((el) => this.disableElement(el));
    } else {
      this.companyUidTargets.forEach((el) => this.handleElement(el, event));
    }
    this.resetInputField();
  }

  disableElement(element) {
    element.required = false;
    element.disabled = true;
    element.value = null;
  }

  enableElement(element) {
    element.required = true;
    element.disabled = false;
  }

  handleElement(element, event) {
    if (element.offsetParent === null) {
      // element currently hidden
      this.disableElement(element);
    } else {
      this.enableElement(element);

      if (event) {
        element.focus();
      }
    }
  }

  resetInputField() {
    this.companyUidTarget.setCustomValidity("");
    this.companyUidTarget.classList.remove("is-invalid");
    this.messageContainerTarget.innerHTML = "";
  }
}
