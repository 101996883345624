import { Controller } from "stimulus";
import { template } from "lodash-es";

export default class extends Controller {
  static targets = ["template"];

  static values = {
    innerHtml: String,
  };

  connect() {
    this.template = template(this.innerHtmlValue);
  }

  update(used, available) {
    this.templateTarget.innerHTML = this.template({
      used: used,
      available: available,
    });
  }
}
