import CostEstimatorController from "./base_controller";
import { initChipCheckboxes } from "../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = [
    "paintingForm",
    "costEstimatorsContainer",
    "insideCostEstimator",
    "outsideCostEstimator",
  ];

  paintingLocation = "";

  connect() {
    super.connect();

    initChipCheckboxes();

    this.handlePaintingLocationChange();
  }

  handlePaintingLocationChange() {
    this.paintingLocation =
      this.paintingFormTarget.elements.painting_location.value;

    if (this.paintingLocation === "inside") {
      this.costEstimatorsContainerTarget.innerHTML =
        this.insideCostEstimatorTarget.innerHTML;
    } else {
      this.costEstimatorsContainerTarget.innerHTML =
        this.outsideCostEstimatorTarget.innerHTML;
    }
  }
}
