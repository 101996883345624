import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["messageLink"];

  connect() {
    $(this.messageLinkTarget).colorbox({
      inline: true,
      width: "40%",
    });
  }
}
