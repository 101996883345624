function handleMultiselectInput(input, button) {
  input.checked = !input.checked

  if (input.checked) {
    button.classList.add('active')
  } else {
    button.classList.remove('active')
  }

  input.dispatchEvent(new Event('toggle'))
}

function handleSingleChipSelection(chipGroupButtons, checkedButton) {
  for (let i = 0; i < chipGroupButtons.length; i += 1) {
    const button = chipGroupButtons[i]
    const input = button.getElementsByTagName('input')[0]

    button.classList.remove('active')
    input.checked = button === checkedButton

    if (button === checkedButton) {
      checkedButton.classList.add('active')
      input.dispatchEvent(new Event('toggle'))
    }
  }
}

export const initChipCheckboxes = () => {
  const chipButtonGroups = document.querySelectorAll('.chip-group[role="radiogroup"]')

  for (let i = 0; i < chipButtonGroups.length; i += 1) {
    const chipGroupButtons = chipButtonGroups[i].getElementsByClassName('chip-checkbox')
    const isMultiselect = chipButtonGroups[i].classList.contains('multiselect')

    for (let i = 0; i < chipGroupButtons.length; i += 1) {
      const button = chipGroupButtons[i]
      const input = button.getElementsByTagName('input')[0]

      if (!button.onclick) {
        button.onclick = () => {
          if (isMultiselect) handleMultiselectInput(input, button)
          else handleSingleChipSelection(chipGroupButtons, button)
        }
      }

      if (input && input.checked) button.classList.add('active')
    }
  }
}

initChipCheckboxes()
