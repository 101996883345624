import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["truncatedText", "fullText"];

  show(event) {
    event.preventDefault();

    this.truncatedTextTarget.classList.add("d-none");
    event.target.remove();

    this.fullTextTarget.classList.remove("d-none");
  }
}
