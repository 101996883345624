import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

// noinspection DuplicatedCode,JSUnresolvedVariable
export default class extends CostEstimatorController {
  static values = { calculationDataObject: Array };

  static targets = ["regroutTilesForm"];

  initialize() {
    initRangeInputs();
    initChipCheckboxes();
  }

  connect() {
    super.connect();

    this.calculateData();
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    const [min, medium, max] = this.calculationDataValue;
    const area = parseInt(this.regroutTilesFormTarget.tiles_area.value);

    this.lowEstimate = Math.round(min * area);
    this.averageEstimate = Math.round(medium * area);
    this.highEstimate = Math.round(max * area);
  }
}
