import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["comment", "submit", "submitWithQuote", "invalidFeedback"];

  static values = {
    maxLength: Number,
  };

  commentTarget: HTMLTextAreaElement;
  submitTarget: HTMLButtonElement;
  submitWithQuoteTarget: HTMLButtonElement;
  invalidFeedbackTarget: HTMLElement;
  maxLengthValue: number;

  connect(): void {
    this.validateComment({ target: this.commentTarget });
  }

  validateComment(event): void {
    if (event.target.value.length > this.maxLengthValue) {
      this.submitTarget.disabled = true;
      this.submitWithQuoteTarget.disabled = true;
      this.invalidFeedbackTarget.style.display = "block";
    } else {
      this.submitTarget.disabled = false;
      this.submitWithQuoteTarget.disabled = false;
      this.invalidFeedbackTarget.style.display = "none";
    }
  }
}
