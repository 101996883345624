import { Controller } from "stimulus";

export default class extends Controller {
  private busyClass = ["busy", "dark"];

  onClick(event): void {
    event.preventDefault();
    this.preloadImages();
    event.currentTarget.classList.add(...this.busyClass);
    // due to lazySizes limitation simply wait a second before triggering
    // print dialog - this should load up all the missing images first
    setTimeout(this.print.bind(this), 900, event.currentTarget);
  }

  private preloadImages(): void {
    const config = (window as any).lazySizesConfig;
    const className = config.lazyClass || "lazyload";
    Array.from(document.getElementsByClassName(className)).forEach(
      (element) => {
        (window as any).lazySizes.loader.unveil(element);
      },
    );
  }

  private print(element): void {
    element.classList.remove(...this.busyClass);
    window.print();
  }
}
