import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollContainer", "heading", "headingContainer", "block"];

  connect() {
    this.headingTargets[0].checked = true;
    this.blockTargets[0].classList.remove("d-none");
    this.blockTargets[0].classList.add("block");
  }

  toggleLists(e) {
    const blockTargetIndex = parseInt(e.target.value);

    for (let i = 0; i < this.headingTargets.length; i += 1) {
      if (i === blockTargetIndex) {
        this.blockTargets[i].classList.remove("d-none");
      } else {
        this.blockTargets[i].classList.add("d-none");
      }
    }

    /**
     * We show the next option when we tap on any category.
     * We use try to show the a bit of the previous option by moving 50 pixels from the actual offset value
     */
    this.scrollContainerTarget.scrollLeft =
      this.headingContainerTargets[blockTargetIndex].offsetLeft - 50;
  }
}
