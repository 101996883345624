import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    // We changed from 'selectize' to @selectize/selectize in order to get rid of unsafe dependencies
    // and address security warnings
    import(
      /* webpackChunkName: "selectize" */ "@selectize/selectize/dist/js/selectize"
    ).then((module) => {
      this.configureSelectize(module.default);

      this.selectTargets.forEach((node) => {
        $(node).selectize({
          plugins: ["select_on_blur"],
          create: false,
          closeAfterSelect: true,
          openOnFocus: true,
          allowEmptyOption: true,
          copyClassesToDropdown: false,
          valueField: "value",
          labelField: "label",
          searchField: "label",
          onItemAdd: function () {
            this.blur();
          },
          render: {
            option: function (item, escape) {
              return `<div class="option">${escape(item.label)}</div>`;
            },
          },
          loadOption: "remote",
          load: function (query, callback) {
            if (!query.length) return callback();

            const fetcher = new Fetcher();
            fetcher
              .request(
                `${node.dataset.url}?term=${encodeURIComponent(query)}&locale=${node.dataset.locale}`,
                {
                  method: "get",
                },
              )
              .then((json) => callback(json))
              .catch(() => {
                serverErrorNotification();
              });
          },
        });
      });
    });
  }

  configureSelectize(module) {
    module.define("select_on_blur", function (options) {
      var self = this;

      options.text =
        options.text ||
        function (option) {
          return option[this.settings.labelField];
        };

      this.onBlur = (function () {
        var original = self.onBlur;

        return function (e) {
          // Do the default actions
          original.apply(this, e);

          // Set the value back
          if (this.currentResults) {
            var searchResults = this.currentResults.items;

            // If noting was selected preselect first results item
            if (!self.items[0] && !(typeof searchResults[0] === "undefined")) {
              this.addItem(searchResults[0].id, true);
            }
          }
        };
      })();
    });
  }
}
