import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = ["gardeningForm"];

  connect() {
    super.connect();

    initRangeInputs();
    initChipCheckboxes();

    this.calculateData();
  }

  get checkedActions() {
    const actions = [];

    this.gardeningFormTarget.gardening_actions.forEach((element) => {
      if (element.checked) {
        actions.push(element.value);
      }
    });

    return actions;
  }

  get checkedActionsImpact() {
    let [low, medium, high] = [0, 0, 0];

    this.checkedActions.forEach((action) => {
      low += this.calculationDataValue[action].low;
      medium += this.calculationDataValue[action].medium;
      high += this.calculationDataValue[action].high;
    });

    return { low, medium, high };
  }

  get gardeningScopeValue() {
    return this.gardeningFormTarget.elements.gardening_scope.value;
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)

    let { low, medium, high } = this.checkedActionsImpact;

    low *= this.gardeningScopeValue;
    medium *= this.gardeningScopeValue;
    high *= this.gardeningScopeValue;

    this.lowEstimate = Math.round(low);
    this.averageEstimate = Math.round(medium);
    this.highEstimate = Math.round(high);
  }
}
