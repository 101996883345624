import { Controller } from "stimulus";

export default class extends Controller {
  private onScrollRunning: boolean;

  private scrollHeight: number;

  private CSS_CLASS = "fixed";

  private footer: HTMLElement;

  connect(): void {
    this.onScrollRunning = false;
    this.scrollHeight = document.documentElement.scrollHeight;
    this.footer = document.querySelector("body > footer");

    if (!this.invisible()) {
      // adjust space on main container - we're using absolute position to avoid flickering during scrolling
      document
        .querySelector("body > .container-for-sticky-footer")
        .setAttribute(
          "style",
          `padding-bottom: ${this.element.getBoundingClientRect().height - 23}px`,
        );
    }
  }

  onScroll(): void {
    if (this.invisible()) {
      return;
    }

    if (!this.onScrollRunning) {
      this.onScrollRunning = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.adjustFooter.bind(this));
      } else {
        setTimeout(this.adjustFooter.bind(this), 300);
      }
    }
  }

  adjustFooter(): void {
    const desktopWidth = 992;
    const viewportWidth = document.documentElement.clientWidth;

    if (viewportWidth <= desktopWidth) {
      this.adjustMobileStickyFooter();
    } else {
      this.adjustDesktopStickyFooter();
    }
  }

  adjustDesktopStickyFooter(): void {
    const box = this.footer.getBoundingClientRect();
    if (box.top < window.innerHeight && box.bottom >= 0) {
      this.unstick();
    } else if (window.scrollY >= 100) {
      this.stick();
    } else {
      this.unstick();
    }

    this.onScrollRunning = false;
  }

  adjustMobileStickyFooter(): void {
    const box = this.footer.getBoundingClientRect();

    if (this.inView()) {
      this.unstick();
    } else {
      if (box.top < window.innerHeight && box.bottom >= 0) {
        this.unstick();
      } else if (window.scrollY >= 100) {
        this.stick();
      } else {
        this.unstick();
      }
    }

    this.onScrollRunning = false;
  }

  unstick(): void {
    this.element.classList.remove(this.CSS_CLASS);
  }

  stick(): void {
    this.element.classList.add(this.CSS_CLASS);
  }

  invisible(): boolean {
    return (this.element as HTMLElement).clientHeight == 0;
  }

  // checks if main cta is fully or partially visible in the viewport since we do not want to show two cta-s at the same time
  inView(): boolean {
    const cta = document.getElementById("cta-main-btn") || false;

    if (cta == false) {
      return false;
    }

    const desktopOnlyCta =
      document.getElementsByClassName("desktop-only")[0] || false;

    if (desktopOnlyCta !== false) {
      return false;
    }

    var box = cta.getBoundingClientRect();
    return this.inViewBox(box);
  }

  inViewBox(box): boolean {
    return (box.bottom < 0 || box.top > this.getWindowSize().h) !== true;
  }

  getWindowSize(): any {
    return {
      w:
        document.body.offsetWidth ||
        document.documentElement.offsetWidth ||
        window.innerWidth,
      h:
        document.body.offsetHeight ||
        document.documentElement.offsetHeight ||
        window.innerHeight,
    };
  }
}
