import { Controller } from "stimulus";
import { notification } from "../components/notification";

export default class extends Controller {
  static targets = ["rows", "alertMessage", "uploadForm", "uploadCategory"];

  onUploadSuccess(event) {
    const json = event.detail[0];

    notification(json.message, "success");
    this.uploadFormTarget.reset();
    this.rowsTarget.querySelector(
      `[data-status="${json.verification}"`,
    ).innerHTML = json.content;
  }

  onUploadError(event) {
    const json = event.detail[0];
    notification(json.error, "error");
  }

  uploadLinkClick(event) {
    this.uploadCategoryTarget.value = event.currentTarget.dataset.type;
  }
}
