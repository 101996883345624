import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emailInput"];

  connect() {
    const emailValue = localStorage.getItem("emailInput");
    if (emailValue) this.emailInputTarget.value = emailValue;
  }

  update() {
    localStorage.setItem("emailInput", this.emailInputTarget.value);
  }

  submit() {
    localStorage.removeItem("emailInput");
  }
}
