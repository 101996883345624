import { Controller } from "stimulus";
import { German } from "flatpickr/dist/l10n/de";
import { French } from "flatpickr/dist/l10n/fr";

export default class extends Controller {
  static targets = ["datePicker", "dateTo", "dateFrom"];
  static values = {
    startDate: String,
    locale: String,
  };

  private datePickerTarget: HTMLInputElement;
  private dateToTarget: HTMLInputElement;
  private dateFromTarget: HTMLInputElement;
  private startDateValue: string;
  private localeValue: "de" | "fr" | "en";

  private localeMapping = {
    de: German,
    fr: French,
    en: German,
  };

  connect() {
    // @ts-ignore
    import("flatpickr/dist/themes/material_blue.css");

    import(/* webpackChunkName: "flatpickr" */ "flatpickr").then((module) => {
      module.default(this.datePickerTarget, {
        locale: this.localeMapping[this.localeValue],
        mode: "range",
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        maxDate: "today",
        onChange: function (selectedDates, dateStr, instance) {
          this.dateFromTarget.value = instance.formatDate(
            selectedDates[0],
            "Y-m-d",
          );

          if (selectedDates[1]) {
            this.dateToTarget.value = instance.formatDate(
              selectedDates[1],
              "Y-m-d",
            );
          } else {
            this.dateToTarget.value = null;
          }
        }.bind(this),
        defaultDate: [this.dateFromTarget.value, this.dateToTarget.value],
        minDate: this.startDateValue,
      });
    });
  }
}
