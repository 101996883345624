import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

const TRIMMING_SCOPE = "trimming";
const FELLING_SCOPE = "felling";
const STUMP_REMOVAL_SCOPE = "stump_removal";

const HEIGHT_VALUES = ["up_to_3", "from_4_to_7", "from_8"];

export default class extends CostEstimatorController {
  static targets = [
    "treeCareForm",
    "scopeInput",
    "trimmingScope",
    "fellingScope",
    "stumpRemovalScope",
  ];

  currentTreeCareAction = null;

  connect() {
    super.connect();

    initRangeInputs();
    initChipCheckboxes();

    this.calculateData();
  }

  get treeCareAction() {
    return this.treeCareFormTarget.elements.tree_care_action.value;
  }

  get isTreeCareActionChanged() {
    return this.currentTreeCareAction !== this.treeCareAction;
  }

  handleScopeTypeChange() {
    if (!this.isTreeCareActionChanged) return;

    switch (this.treeCareAction) {
      case TRIMMING_SCOPE:
        this.scopeInputTarget.innerHTML = this.trimmingScopeTarget.innerHTML;
        break;
      case FELLING_SCOPE:
        this.scopeInputTarget.innerHTML = this.fellingScopeTarget.innerHTML;
        break;
      case STUMP_REMOVAL_SCOPE:
        this.scopeInputTarget.innerHTML =
          this.stumpRemovalScopeTarget.innerHTML;
        break;
    }

    this.currentTreeCareAction = this.treeCareAction;
    initRangeInputs();
  }

  // app/services/cost_estimator_v3_data/gardening.rb tree_care->trimming
  // app/services/cost_estimator_v3_data/gardening.rb tree_care->felling
  // app/services/cost_estimator_v3_data/gardening.rb tree_care->stump_removal
  get calculatePriceRangeData() {
    if (this.currentTreeCareAction === TRIMMING_SCOPE) {
      const heightValueIndex =
        this.treeCareFormTarget.elements.trimming_height.value;
      const height = HEIGHT_VALUES[heightValueIndex];

      return this.calculationDataValue[TRIMMING_SCOPE][height];
    } else if (this.currentTreeCareAction === FELLING_SCOPE) {
      const heightValueIndex =
        this.treeCareFormTarget.elements.felling_height.value;
      const height = HEIGHT_VALUES[heightValueIndex];

      return this.calculationDataValue[FELLING_SCOPE][height];
    } else {
      return this.calculationDataValue[STUMP_REMOVAL_SCOPE];
    }
  }

  get scopeValue() {
    if (this.currentTreeCareAction === TRIMMING_SCOPE) {
      return this.treeCareFormTarget.elements.trimming_scope.value;
    } else if (this.currentTreeCareAction === FELLING_SCOPE) {
      return this.treeCareFormTarget.elements.felling_scope.value;
    } else {
      return this.treeCareFormTarget.elements.stump_removal_scope.value;
    }
  }

  calculateData() {
    this.handleScopeTypeChange();
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)

    let { low, medium, high } = this.calculatePriceRangeData;

    low *= this.scopeValue;
    medium *= this.scopeValue;
    high *= this.scopeValue;

    this.lowEstimate = Math.round(low);
    this.averageEstimate = Math.round(medium);
    this.highEstimate = Math.round(high);
  }
}
