import { Controller } from "stimulus";
import { ScrollSpy } from "bootstrap";

export default class extends Controller {
  static targets = ["navigation"];

  connect() {
    // Dig into internals so the first nav element is highlighted
    // starting from the top of the page.
    //
    // We need to override .refresh as scrollspy recalculates
    // target positions when container height changed.
    var scrollSpy = ScrollSpy.getOrCreateInstance(this.navigationTarget);
    var originalRefresh = scrollSpy.refresh;
    scrollSpy.refresh = function () {
      originalRefresh.apply(scrollSpy, arguments);
      scrollSpy._offsets[0] = 0;
      scrollSpy._process();
    };
  }
}
