import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["reason", "details", "detailsWrapper"];

  connect() {
    this.onChange();
  }

  onChange() {
    if (this.otherReasonSelected) {
      this.detailsWrapperTarget.classList.remove("hidden");
      this.detailsTarget.required = true;
    } else {
      this.detailsWrapperTarget.classList.add("hidden");
      this.detailsTarget.required = false;
    }
  }

  get otherReasonSelected() {
    return this.selectedValue === "other";
  }

  get selectedValue() {
    const item = this.reasonTargets.find((item) => item.checked);
    return item ? item.value : null;
  }
}
