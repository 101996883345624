import CostEstimatorController from "./base_controller";
import { initRangeInputs } from "../../lib/range-slider";
import { initChipCheckboxes } from "../../lib/chip-checkbox";

const LANDSCAPING = "landscaping";
const TREE_CARE = "tree_care";
const GARDENING = "gardening";

export default class extends CostEstimatorController {
  static targets = [
    "gardeningForm",
    "costEstimatorsContainer",
    "gardeningCostEstimator",
    "treeCareCostEstimator",
    "landscapingCostEstimator",
  ];

  connect() {
    super.connect();

    initRangeInputs();
    initChipCheckboxes();

    this.handleGardeningActionChange();
  }

  handleGardeningActionChange() {
    const gardeningAction =
      this.gardeningFormTarget.elements.gardening_action.value;

    switch (gardeningAction) {
      case LANDSCAPING:
        this.costEstimatorsContainerTarget.innerHTML =
          this.landscapingCostEstimatorTarget.innerHTML;
        break;
      case TREE_CARE:
        this.costEstimatorsContainerTarget.innerHTML =
          this.treeCareCostEstimatorTarget.innerHTML;
        break;
      case GARDENING:
        this.costEstimatorsContainerTarget.innerHTML =
          this.gardeningCostEstimatorTarget.innerHTML;
    }
  }
}
