import CostEstimatorController from "./base_controller";
import { initChipCheckboxes } from "../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = [
    "layFloorRadioButton",
    "maintainWoodenFloorRadioButton",
    "repairFloorRadioButton",
    "layFloorCostEstimator",
    "maintainWoodenFloorCostEstimator",
    "repairFloorCostEstimator",
    "costEstimatorsContainer",
  ];

  connect() {
    super.connect();

    initChipCheckboxes();
    this.costEstimatorsRadioButtons = [
      this.layFloorRadioButtonTarget,
      this.maintainWoodenFloorRadioButtonTarget,
      this.repairFloorRadioButtonTarget,
    ];
    this.toggleFlooringCostEstimator();
  }

  toggleFlooringCostEstimator() {
    switch (this.checkedCostEstimator) {
      case "lay_floor":
        this.costEstimatorsContainerTarget.innerHTML =
          this.layFloorCostEstimatorTarget.innerHTML;
        break;
      case "maintain_wooden_floor":
        this.costEstimatorsContainerTarget.innerHTML =
          this.maintainWoodenFloorCostEstimatorTarget.innerHTML;
        break;
      case "repair_floor":
        this.costEstimatorsContainerTarget.innerHTML =
          this.repairFloorCostEstimatorTarget.innerHTML;
        break;
      default:
        throw Error("No cost estimator checked");
    }
  }

  /**
   *
   * @returns {'lay_floor'|'maintain_wooden_floor'|'repair_floor'}
   */
  get checkedCostEstimator() {
    for (let i = 0; i < this.costEstimatorsRadioButtons.length; i += 1) {
      if (this.costEstimatorsRadioButtons[i].checked) {
        return this.costEstimatorsRadioButtons[i].value;
      }
    }

    throw Error("No laying method checked");
  }
}
