import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textarea"];

  private textareaTarget: HTMLTextAreaElement;

  connect() {
    this.textareaTarget.setAttribute(
      "style",
      "height:" + this.textareaTarget.scrollHeight + "px;overflow-y:hidden;",
    );
  }

  resize() {
    this.textareaTarget.style.height = "auto";
    this.textareaTarget.style.height = this.textareaTarget.scrollHeight + "px";
  }
}
