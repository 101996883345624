import intlTelInput from "intl-tel-input";

export const createPhoneInput = (phoneInputField, containerClasses) => {
  if (!phoneInputField) return null;

  return import(
    /* webpackChunkName: "intl-tel-input-utils" */ "intl-tel-input/build/js/utils"
  ).then(() => {
    // eval(module.default)
    return intlTelInput(phoneInputField, {
      customContainer: containerClasses,
      localizedCountries: { de: "Deutschland" },
      onlyCountries: ["ch", "de", "at", "li"],
      preferredCountries: ["ch"],
      initialCountry: "ch",
    });
  });
};
