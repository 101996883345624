import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = ["layTilesForm"];

  connect() {
    super.connect();
    this.initValues();

    initRangeInputs();
    initChipCheckboxes();

    this.calculateData();
  }

  initValues() {
    this.layScopePrices = this.calculationDataValue["laying_scope"];
  }

  get layingScopePrices() {
    const layingLocation = this.layTilesFormTarget.laying_location.value;
    const layingLocationPrices =
      this.calculationDataValue["laying_location"][layingLocation];
    const area = parseInt(this.layTilesFormTarget.laying_area.value);

    let min, medium, max;

    if (layingLocationPrices) {
      [min, medium, max] = layingLocationPrices;
    } else {
      [min, medium, max] = this.calculationDataValue["laying_scope"];
    }

    min *= area;
    medium *= area;
    max *= area;

    if (this.layTilesFormTarget.will_tradesman_provide_tiles.checked) {
      const [lowFactor, mediumFactor, highFactor] =
        this.calculationDataValue["laying_material"];

      min += lowFactor * area;
      medium += mediumFactor * area;
      max += highFactor * area;
    }

    return [min, medium, max];
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    const [min, medium, max] = this.layingScopePrices;

    this.lowEstimate = Math.round(min);
    this.averageEstimate = Math.round(medium);
    this.highEstimate = Math.round(max);
  }
}
