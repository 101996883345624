import { Controller } from "stimulus";

export default class extends Controller {
  onToggleSuccess(event) {
    const json = event.detail[0];
    event.currentTarget.innerHTML = json.display;
    event.currentTarget.classList.remove("busy");
  }

  onToggle(event) {
    event.currentTarget.classList.add("busy");
  }
}
