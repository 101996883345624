import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["authenticationForm", "submitButton", "emailInput"];

  connect() {
    // Workaround to with issue in Chrome when not using turbo https://github.com/hotwired/stimulus/issues/328
    setTimeout(
      () => (this.submitButtonTarget.disabled = !this.emailInputTarget.value),
      1,
    );
  }

  toggleButtonAndSave(event) {
    this.submitButtonTarget.disabled = !event.target.value;
    localStorage.setItem("emailInput", event.target.value);
  }
}
