import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["togglable", "replace"];

  show(e) {
    this.togglableTarget.classList.remove("d-none");
    e.target.remove();
  }

  replace(e) {
    this.togglableTarget.classList.remove("d-none");
    this.replaceTarget.classList.add("d-none");
    e.target.remove();
  }
}
