import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal"];
  static values = { show: Boolean };

  private modalTarget: HTMLElement;
  private showValue: boolean;

  connect(): void {
    if (this.showValue) {
      const modal = new Modal(this.modalTarget);
      modal.show();
    }
  }
}
