import CostEstimatorController from "./base_controller";
import { initRangeInputs } from "../../lib/range-slider";

export default class extends CostEstimatorController {
  static targets = ["rooms"];

  connect() {
    super.connect();

    initRangeInputs();
    this.calculateData();
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)

    const rooms = this.roomsTarget.value;

    this.averageEstimate = this.calculationDataValue.rooms[rooms];
  }
}
