jQuery.fn.scrollToMe = function(callback) {
  var offset = this.offset()

  if (!offset) {
    return
  }
  jQuery('html,body').animate({
    scrollTop: offset.top - 100
  }, callback)

  return this
}
