import { Controller } from "stimulus";
import { notification } from "../components/notification";

export default class extends Controller {
  static outlets = ["invitations-info-box"];

  onSuccess(event) {
    const json = event.detail[0];
    $.colorbox.close();

    if (json.force_page_reload === false) {
      const timeAgoStatus = document.createElement("span");
      timeAgoStatus.innerHTML = json.confirmed_at_time_ago;
      timeAgoStatus.setAttribute("class", "small silent");

      const container = document.querySelector(`#proposal_${json.proposal_id}`);
      container.querySelector(".cta-link").replaceWith(timeAgoStatus);
      container.querySelector(".status_info").innerHTML =
        json.status_info_partial;

      const feeds = container.querySelector(".feeds");
      if (feeds) {
        feeds.insertAdjacentHTML("beforeend", json.last_feed_partial);
        feeds.lastElementChild.scrollIntoView({ block: "center" });
        feeds.lastElementChild.classList.add("animated", "pulse");
        container
          .querySelector(".comments-container")
          .insertAdjacentHTML("beforeend", json.new_job_comment_partial);
      }

      const domId = container.querySelector(`#${json.dom_id}`);
      if (domId) {
        document.querySelector(
          ".contact-request-confirm-success",
        ).style.display = "block";
        domId.innerHTML = json.contact_request_partial;
        domId.classList.add("animated", "pulse");
      }

      const infoBox = document.querySelector(".info_box_text");
      if (infoBox) {
        infoBox.dataset.available = parseInt(infoBox.dataset.available) - 1;
        infoBox.dataset.used = parseInt(infoBox.dataset.used) + 1;
        this.invitationsInfoBoxOutlet.update(
          infoBox.dataset.used,
          infoBox.dataset.available,
        );
      }
    } else {
      window.location.reload();
    }
  }

  onError(event) {
    notification(event.detail[0].error, "error");
  }
}
