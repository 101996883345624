$(document).ready(function () {
  $("a.confirm-quote").colorbox({
    inline: true,
    width: "40%",
  });

  $("a.reject-quote").colorbox({
    inline: true,
    width: "40%",
  });

  $(".proposal .tradesman-row").on("click", function () {
    window.location = $(this).data("url");
  });

  // cancel replying and move form back to it's original place
  $(".proposal").on("click", ".cancel", function (e) {
    var $commentForm = $(this).parents(".comments").find("form");
    $commentForm.appendTo($(this).parents(".comments"));
    $commentForm.find('input[name="comment[comment_id]"]').val("");

    $(this).hide();

    if ($commentForm.data("hide")) {
      $commentForm.hide();
    }
    e.preventDefault();
  });
});
