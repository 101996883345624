import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "masterCount",
    "masterRate",
    "masterCost",
    "skilledWorkerCount",
    "skilledWorkerRate",
    "skilledWorkerCost",
    "helperCount",
    "helperRate",
    "helperCost",
    "apprenticeCount",
    "apprenticeRate",
    "apprenticeCost",
    "totalPersonnelCost",
    "distance",
    "costPerKm",
    "totalVehicleCost",
    "durationPerTrip",
    "numberOfTrips",
    "vehicleAndPersonnelCosts",
    "vat",
    "totalCostIncludingVAT",
  ];

  private durationPerTripTarget: HTMLInputElement;
  private numberOfTripsTarget: HTMLInputElement;
  private masterCountTarget: HTMLInputElement;
  private masterRateTarget: HTMLInputElement;
  private masterCostTarget: HTMLInputElement;
  private skilledWorkerCountTarget: HTMLInputElement;
  private skilledWorkerRateTarget: HTMLInputElement;
  private skilledWorkerCostTarget: HTMLInputElement;
  private helperCountTarget: HTMLInputElement;
  private helperRateTarget: HTMLInputElement;
  private helperCostTarget: HTMLInputElement;
  private apprenticeCountTarget: HTMLInputElement;
  private apprenticeRateTarget: HTMLInputElement;
  private apprenticeCostTarget: HTMLInputElement;
  private totalPersonnelCostTarget: HTMLElement;
  private distanceTarget: HTMLInputElement;
  private costPerKmTarget: HTMLInputElement;
  private totalVehicleCostTarget: HTMLInputElement;
  private vehicleAndPersonnelCostsTarget: HTMLElement;
  private vatTarget: HTMLInputElement;
  private totalCostIncludingVATTarget: HTMLElement;

  private masterCost: number;
  private skilledWorkerCost: number;
  private helperCost: number;
  private apprenticeCost: number;
  private totalVehicleCost: number;

  clearTotalResult() {
    this.totalCostIncludingVATTarget.textContent = "-";
    this.vehicleAndPersonnelCostsTarget.textContent = "-";
    this.vatTarget.value = "";
  }

  calculatePartialCost(event: Event) {
    event.preventDefault();
    this._calculatePartialCost();
    this._updatePartialResults();
  }

  calculateFinalCost(event: Event) {
    event.preventDefault();
    this._calculatePartialCost();
    this._updatePartialResults();

    const totalCost = this._totalPersonnelCost() + this.totalVehicleCost;
    const vat = totalCost * 0.081;
    const totalCostIncludingVAT = totalCost + vat;

    this._updateResults(totalCost, vat, totalCostIncludingVAT);
  }

  private _calculateCost(countTarget, rateTarget, tripRate) {
    return (
      parseFloat(countTarget.value || 0) *
      parseFloat(rateTarget.value || 0) *
      tripRate
    );
  }

  private _calculatePartialCost() {
    const tripRate =
      (parseFloat(this.durationPerTripTarget.value) / 60) *
      parseFloat(this.numberOfTripsTarget.value);

    this.masterCost = this._calculateCost(
      this.masterCountTarget,
      this.masterRateTarget,
      tripRate,
    );
    this.skilledWorkerCost = this._calculateCost(
      this.skilledWorkerCountTarget,
      this.skilledWorkerRateTarget,
      tripRate,
    );
    this.helperCost = this._calculateCost(
      this.helperCountTarget,
      this.helperRateTarget,
      tripRate,
    );
    this.apprenticeCost = this._calculateCost(
      this.apprenticeCountTarget,
      this.apprenticeRateTarget,
      tripRate,
    );

    this.totalVehicleCost =
      parseFloat(this.distanceTarget.value) *
      parseFloat(this.costPerKmTarget.value) *
      parseFloat(this.numberOfTripsTarget.value);
  }

  private _updateResults(totalCost, vat, totalCostIncludingVAT) {
    this.vehicleAndPersonnelCostsTarget.textContent = `CHF ${totalCost.toFixed(2)}`;
    this.vatTarget.value = vat.toFixed(2);
    this.totalCostIncludingVATTarget.textContent = `CHF ${totalCostIncludingVAT.toFixed(2)}`;
  }

  private _updatePartialResults() {
    this.masterCostTarget.value = this.masterCost.toFixed(2);
    this.skilledWorkerCostTarget.value = this.skilledWorkerCost.toFixed(2);
    this.helperCostTarget.value = this.helperCost.toFixed(2);
    this.apprenticeCostTarget.value = this.apprenticeCost.toFixed(2);

    this.totalVehicleCostTarget.value = this.totalVehicleCost.toFixed(2);
    this.totalPersonnelCostTarget.textContent = `CHF ${this._totalPersonnelCost().toFixed(2)}`;
  }

  private _totalPersonnelCost() {
    return (
      this.masterCost +
      this.skilledWorkerCost +
      this.helperCost +
      this.apprenticeCost
    );
  }
}
