import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

export default class extends CostEstimatorController {
  static targets = [
    "parquet",
    "laminate",
    "vinylOrLinoleum",
    "carpet",
    "pvc",
    "solidWood",
    "floorSurface",
  ];

  connect() {
    super.connect();

    this.flooringTargers = [
      this.parquetTarget,
      this.laminateTarget,
      this.vinylOrLinoleumTarget,
      this.carpetTarget,
      this.pvcTarget,
      this.solidWoodTarget,
    ];

    this.calculateData();
    initRangeInputs();
    initChipCheckboxes();
  }

  /**
   *
   * @returns {'parquet'|'laminate'|'vinyl_or_linoleum'|'carpet'|'solid_wood'|'pvc'}
   */
  get checkedFlooring() {
    for (let i = 0; i < this.flooringTargers.length; i += 1) {
      if (this.flooringTargers[i].checked) {
        return this.flooringTargers[i].value;
      }
    }

    throw Error("No flooring checked");
  }

  /**
   * @returns {
   *   {
   *    "parquet": {"low": number, "medium": number, "high": number},
   *    "laminate": {"low": number, "medium": number, "high": number},
   *    "vinyl_or_linoleum": {"low": number, "medium": number, "high": number},
   *    "carpet": {"low": number, "medium": number, "high": number},
   *    "solid_wood": {"low": number, "medium": number, "high": number},
   *    "pvc": {"low": number, "medium": number, "high": number}
   *   }
   * }
   */
  get prices() {
    return this.calculationDataValue.prices;
  }

  /**
   *
   * @returns {{"low": number, "medium": number, "high": number}}
   */
  get currentFlooringPrices() {
    return this.prices[this.checkedFlooring];
  }

  /**
   *
   * @returns {number}
   */
  get surface() {
    return parseInt(this.floorSurfaceTarget.value);
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    this.lowEstimate = Math.round(
      this.currentFlooringPrices.low * this.surface,
    );
    this.averageEstimate = Math.round(
      this.currentFlooringPrices.medium * this.surface,
    );
    this.highEstimate = Math.round(
      this.currentFlooringPrices.high * this.surface,
    );
  }
}
