import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["state"];

  checkState = true;

  toggleAll(event) {
    event.preventDefault();

    // note: keep the state between toggles so it works nicely for already pre-selected targets
    this.stateTargets.forEach((input) => (input.checked = this.checkState));
    this.checkState = !this.checkState;
  }
}
