import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { notification } from "../components/notification";

export default class extends Controller {
  static values = {
    jobId: Number,
    leadPrice: Number,
  };

  static targets = [
    "form",
    "modal",
    "suggestedLead",
    "suggestedQuote",
    "suggestedLeadError",
    "suggestedQuoteError",
  ];

  connect() {
    import("sweetalert2/src/sweetalert2.scss");
    this.fetcher = new Fetcher({ parseErrorResponse: true });
  }

  create(event) {
    event.preventDefault();

    const url = this.formTarget.action;

    this.fetcher
      .request(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_feedback_lead_price: {
            job_id: this.jobIdValue,
            lead_price: this.leadPriceValue,
            suggested_lead: this.suggestedLeadTarget.value,
            suggested_quote: this.suggestedQuoteTarget.value,
          },
        }),
      })
      .then((res) => {
        if (res.error) {
          const errors = res.error[0];

          if (errors.suggested_quote) {
            this.suggestedQuoteErrorTarget.textContent = errors.suggested_quote;
          } else {
            this.suggestedQuoteErrorTarget.textContent = "";
          }

          if (errors.suggested_lead) {
            this.suggestedLeadErrorTarget.textContent = errors.suggested_lead;
          } else {
            this.suggestedLeadErrorTarget.textContent = "";
          }
        } else {
          notification(res.message, "success");
          $(this.modalTarget).modal("hide");
          document.getElementById("priceFeedBackLink").classList.add("d-none");
        }
      });
  }
}
