import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect(): void {
    const modal = new Modal(this.options);
    modal.show();
  }

  get options(): any {
    return JSON.parse(this.data.get("options"));
  }
}
