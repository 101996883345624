import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

// noinspection DuplicatedCode,JSUnresolvedVariable
export default class extends CostEstimatorController {
  static targets = ["outsidePaintingForm", "cleaningBalconyBlock"];

  connect() {
    super.connect();
    this.initValues();

    initRangeInputs();
    initChipCheckboxes();

    this.calculateData();
  }

  initValues() {
    this.upfrontCleaning = false;
    this.basicOutsidePrices = this.calculationDataValue["outside_area"];
    this.basicUpfrontCleaningPrices =
      this.calculationDataValue["upfront_cleaning"];
    this.basicBalconyCleaningPrices =
      this.calculationDataValue["cleaning_balcony"];
  }

  toggleCleaningBalcony() {
    if (
      this.outsidePaintingFormTarget.elements.painting_upfront_cleaning.checked
    ) {
      this.upfrontCleaning = true;
      this.cleaningBalconyBlockTarget.classList.remove("d-none");
    } else {
      this.upfrontCleaning = false;
      this.cleaningBalconyBlockTarget.classList.add("d-none");
    }

    this.calculateData();
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    let [min, medium, max] = this.outsideAreaPrices;

    if (this.upfrontCleaning) {
      min += this.upfrontCleaningPrices.min;
      medium += this.upfrontCleaningPrices.medium;
      max += this.upfrontCleaningPrices.max;
    }

    if (
      this.outsidePaintingFormTarget.elements.painting_cleaning_balcony.checked
    ) {
      min += this.cleaningBalconyPrices.min;
      medium += this.cleaningBalconyPrices.medium;
      max += this.cleaningBalconyPrices.max;
    }

    this.lowEstimate = Math.round(min);
    this.averageEstimate = Math.round(medium);
    this.highEstimate = Math.round(max);
  }

  get outsideAreaPrices() {
    let [min, medium, max] = this.basicOutsidePrices;

    min *= this.inputArea;
    medium *= this.inputArea;
    max *= this.inputArea;

    return [min, medium, max];
  }

  get inputArea() {
    return parseInt(
      this.outsidePaintingFormTarget.elements.painting_outside_area.value,
    );
  }

  get upfrontCleaningPrices() {
    let [min, medium, max] = this.basicUpfrontCleaningPrices;

    min *= this.inputArea;
    medium *= this.inputArea;
    max *= this.inputArea;

    return { min, medium, max };
  }

  get cleaningBalconyPrices() {
    const [min, medium, max] = this.basicBalconyCleaningPrices;

    return { min, medium, max };
  }
}
