import { Controller } from "stimulus";

function getCurrentCEValue(range) {
  return parseInt(
    document.getElementsByClassName(`cost-estimator-${range}-estimate`)[0]
      .innerHTML,
  );
}

function calculationRowTemplate({ id, low, average, high, title }) {
  return `
    <div class="row align-items-center mb-8">
      <div class="col-5"> <h4>${title}</h4> </div>
      <div class="col-5">
        <h5 class="bold text-secondary">
          CHF
          <span>${average}</span>.-
        </h5>
        <h6 class="mb-0">
          from
          <b>CHF
            <span>${low}</span>.-
          </b>
          to
          <b>
            CHF
            <span>${high}</span>.-
          </b>
        </h6>
      </div>
      <div class="col">
        <button data-id="${id}" 
                class="btn btn-lg v2-btn-secondary p-2" 
                data-action="cost-estimators--meta#removeCalculation">
          <i class="fa fa-times m-0 color-gray1"></i>
        </button>
      </div>
    </div>
  `;
}

export default class extends Controller {
  static values = {
    titleTranslations: Object,
  };

  static targets = [
    "form",
    "floors",
    "painting",
    "tiles",
    "newEstimationButton",
    "selectCostEstimatorContainer",
    "selectCostEstimatorInputField",
    "costEstimatorForm",
    "lowEstimate",
    "averageEstimate",
    "highEstimate",
    "lowEstimateMobile",
    "averageEstimateMobile",
    "highEstimateMobile",
    "calculationRows",
  ];

  // {
  //  id: {number},
  //  low: {number},
  //  medium: {number},
  //  high: {number}
  // }
  costEstimatorCalculations = {};

  currentCostEstimator = null;

  uuid = 0;

  metaLowEstimate = 0;

  metaAverageEstimate = 0;

  metaHighEstimate = 0;

  addCalculation() {
    this.uuid += 1;

    const calculation = {
      id: this.uuid,
      title: this.titleTranslationsValue[this.currentCostEstimator],
      low: getCurrentCEValue("low"),
      average: getCurrentCEValue("average"),
      high: getCurrentCEValue("high"),
    };

    this.costEstimatorCalculations[this.uuid] = calculation;
    this.addCalculationRow(calculation);
    this.appendCalculationToTotal(calculation);
    this.hideCostEstimatorForm();
  }

  removeCalculation(event) {
    const id = event.currentTarget.dataset.id;
    const calculation = this.costEstimatorCalculations[id];

    this.metaLowEstimate -= calculation.low;
    this.metaAverageEstimate -= calculation.average;
    this.metaHighEstimate -= calculation.high;

    document.getElementById(`cost-estimator-result-${id}`).remove();
    delete this.costEstimatorCalculations[event.target.dataset.id];
    this.assignCalculationValues();
  }

  appendCalculationToTotal(calculation) {
    this.metaLowEstimate += calculation.low;
    this.metaAverageEstimate += calculation.average;
    this.metaHighEstimate += calculation.high;
    this.assignCalculationValues();
  }

  assignCalculationValues() {
    this.lowEstimateTarget.innerHTML = this.metaLowEstimate;
    this.averageEstimateTarget.innerHTML = this.metaAverageEstimate;
    this.highEstimateTarget.innerHTML = this.metaHighEstimate;

    // separate view for mobile
    this.lowEstimateMobileTarget.innerHTML = this.metaLowEstimate;
    this.averageEstimateMobileTarget.innerHTML = this.metaAverageEstimate;
    this.highEstimateMobileTarget.innerHTML = this.metaHighEstimate;
  }

  addCalculationRow(calculation) {
    const template = calculationRowTemplate(calculation);
    const rowElement = document.createElement("div");

    rowElement.id = `cost-estimator-result-${calculation.id}`;
    rowElement.classList.add("border-bottom-1", "mt-8", "mb-16");
    rowElement.innerHTML = template;

    this.calculationRowsTarget.appendChild(rowElement);
  }

  hideCostEstimatorForm() {
    this.newEstimationButtonTarget.classList.remove("d-none");
    this.selectCostEstimatorContainerTarget.classList.add("d-none");
  }

  addCostEstimator() {
    this.newEstimationButtonTarget.classList.add("d-none");
    this.selectCostEstimatorContainerTarget.classList.remove("d-none");
    this.resetCostEstimatorSelectField();
  }

  resetCostEstimatorSelectField() {
    this.selectCostEstimatorInputFieldTarget.value = "";
    this.selectCostEstimatorInputFieldTarget.dispatchEvent(new Event("change"));
  }

  displayCostEstimator(event) {
    this.currentCostEstimator = event.target.value;

    switch (this.currentCostEstimator) {
      case "floors":
        this.costEstimatorFormTarget.innerHTML = this.floorsTarget.innerHTML;
        break;
      case "painting":
        this.costEstimatorFormTarget.innerHTML = this.paintingTarget.innerHTML;
        break;
      case "tiles":
        this.costEstimatorFormTarget.innerHTML = this.tilesTarget.innerHTML;
        break;
      default:
        this.costEstimatorFormTarget.innerHTML = null;
    }
  }
}
