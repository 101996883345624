import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["togglePassword", "password", "passwordIcon", "phone"];

  initialize() {
    this.passwordStates = ["password", "text"];
    this.passwordIconStates = ["fa-eye", "fa-eye-slash"];
    this.currentPasswordState = 0; // cycle 0/1 index
  }

  togglePassword(event) {
    const oldState = this.currentPasswordState;
    this.currentPasswordState = this.newPasswordState;

    this.passwordTarget.type = this.passwordStates[this.currentPasswordState];
    this.passwordIconTarget.classList.remove(this.passwordIconStates[oldState]);
    this.passwordIconTarget.classList.add(
      this.passwordIconStates[this.currentPasswordState],
    );

    event.preventDefault();
  }

  get newPasswordState() {
    return this.currentPasswordState === 0 ? 1 : 0;
  }
}
