import CostEstimatorController from "./base_controller";
import { initChipCheckboxes } from "../../lib/chip-checkbox";

const LAY_TILES = "lay_tiles";
const REPAIR_TILES = "repair_tiles";
const REGROUT_TILES = "regrout_tiles";

export default class extends CostEstimatorController {
  static targets = [
    "tilesForm",
    "costEstimatorsContainer",
    "layTilesCostEstimator",
    "repairTilesCostEstimator",
    "regroutTilesCostEstimator",
  ];

  connect() {
    super.connect();

    initChipCheckboxes();

    this.handleTilesCostEstimatorChange();
  }

  handleTilesCostEstimatorChange() {
    const tilingAction = this.tilesFormTarget.elements.tiling_action.value;

    switch (tilingAction) {
      case LAY_TILES:
        this.costEstimatorsContainerTarget.innerHTML =
          this.layTilesCostEstimatorTarget.innerHTML;
        break;
      case REPAIR_TILES:
        this.costEstimatorsContainerTarget.innerHTML =
          this.repairTilesCostEstimatorTarget.innerHTML;
        break;
      case REGROUT_TILES:
        this.costEstimatorsContainerTarget.innerHTML =
          this.regroutTilesCostEstimatorTarget.innerHTML;
    }
  }
}
