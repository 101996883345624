import { Controller } from "stimulus";
import { maskInput } from "vanilla-text-mask";

export default class extends Controller {
  static targets = ["companyUid"];

  connect() {
    maskInput({
      inputElement: this.companyUidTarget,
      mask: this.mask,
      placeholderChar: "_",
    });
  }

  get mask() {
    return JSON.parse(this.data.get("config")).map((el) =>
      el.startsWith("\\") ? new RegExp(el) : el,
    );
  }
}
