import { Controller } from "stimulus";

export default class extends Controller {
  private openModalValue: boolean;
  private ctaLinkTarget: HTMLElement;

  static values = {
    openModal: Boolean,
  };

  static targets = ["ctaLink"];

  connect(): void {
    if (this.openModalValue) {
      setTimeout(() => {
        // NOTE: there is a colorbox hook on the cta link; just click it to trigger a modal
        this.ctaLinkTarget.click();
      }, 1000);
    }
  }
}
