// Used all over the place...
$(function () {
  // Add job images for existing jobs.
  $("a.add-file-option").colorbox({
    inline: true,
    width: "40%",
    // Previously selected files should always be unselected when opening colorbox.
    onOpen: function () {
      $(".smallTextbox").val("");
    },
    onClosed: function () {
      $(".smallTextbox").val("");
    },
  });

  window.switch_to_job_edit_form = function (event) {
    $("#job_description_form_uneditable").hide();
    $("#job_description_form_editable")
      .show()
      .find("input:visible:first")
      .focus();
    event.preventDefault();
  };

  window.switch_to_job_description_form = function (event) {
    $("#job_description_form_uneditable").show();
    $("#job_description_form_editable").hide();
    event.preventDefault();
  };
});
