import { Controller } from "stimulus";
import { template } from "lodash-es";

export default class extends Controller {
  static values = {
    jobs: String,
  };

  static targets = ["listGroup", "listItemTemplate"];

  connect() {
    this.listItemTemplate = template(this.listItemTemplateTarget.innerHTML);
    this.jobs = JSON.parse(this.jobsValue);
    this.currentIndex = 4;
    this.loopJobList();
  }

  loopJobList() {
    // we need some time before we remove animate class and add it again
    this.listGroupTarget.classList.add("animate");

    // we allow .5s animation to run, and then we remove animated class
    setTimeout(() => {
      this.listGroupTarget.classList.remove("animate");
      this.appendNextJob();
      this.removeLastChild();
    }, 500);

    // recursively call function randomly between 3 and 4.5s
    setTimeout(
      () => this.loopJobList(),
      Math.round(Math.random() * 1500) + 3000,
    );
  }

  removeLastChild() {
    const element = this.listGroupTarget.lastElementChild;
    this.listGroupTarget.removeChild(element);
  }

  appendNextJob() {
    this.currentIndex = (this.currentIndex + 1) % this.jobs.length;
    const job = this.jobs[this.currentIndex];
    const { title, category_name, cost } = job;
    const data = { title, category_name, cost, cost_chf: cost * 3 };

    const listElement = this.listItemTemplate(data);
    const div = document.createElement("div");
    div.innerHTML = listElement.trim();

    this.listGroupTarget.prepend(div.firstChild);
  }
}
