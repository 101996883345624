import CostEstimatorController from "../base_controller";
import { initRangeInputs } from "../../../lib/range-slider";
import { initChipCheckboxes } from "../../../lib/chip-checkbox";

// noinspection DuplicatedCode,JSUnresolvedVariable
export default class extends CostEstimatorController {
  static values = { calculationDataObject: Array };

  static targets = ["repairTilesForm"];

  initialize() {
    initRangeInputs();
    initChipCheckboxes();
  }

  connect() {
    super.connect();

    this.calculateData();
  }

  calculateData() {
    this.hideCalculation(); // remove this to make it dynamic (easier for testing)
    const [min, medium, max] = this.calculationDataValue;
    const tilesCount = parseInt(this.repairTilesFormTarget.tiles_count.value);

    this.lowEstimate = Math.round(min * tilesCount);
    this.averageEstimate = Math.round(medium * tilesCount);
    this.highEstimate = Math.round(max * tilesCount);
  }
}
