import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";
import { createPhoneInput } from "../components/twilio_lookup";

export default class extends Controller {
  static targets = ["phone"];
  static values = {
    validationMessage: String, // html5 validation message
  };

  private phoneTarget: HTMLInputElement;
  private phoneInput: ReturnType<typeof intlTelInput>;
  private validationMessageValue: string;

  async initialize(): Promise<void> {
    this.phoneInput = await createPhoneInput(this.phoneTarget, "w-100");
  }

  reset(): void {
    this.phoneTarget.setCustomValidity("");
    this.phoneTarget.classList.remove("is-invalid");
  }

  validate(): void {
    // NOTE: some fields might be optional
    if (!this.phoneTarget.required && this.phoneTarget.value === "") {
      this.reset();
      return;
    }

    // NOTE: false indicates that we also accept landlines
    if (!this.phoneInput.isValidNumber(false)) {
      this.phoneTarget.classList.add("is-invalid");
      this.phoneTarget.setCustomValidity(this.validationMessageValue);
      this.phoneTarget.reportValidity();
      this.phoneTarget.value = this.phoneInput.getNumber();
    } else {
      this.reset();
    }
  }
}
