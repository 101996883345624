import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["newsletterForm", "name", "email"];

  url() {
    return `https://ofri.us1.list-manage.com/subscribe?u=8d2ad84d62cafd0b30bbc8575&id=e0ec3c44da&MERGE2=${this.name()}&MERGE0=${this.email()}`;
  }

  name() {
    return this.nameTarget.value;
  }

  email() {
    return this.emailTarget.value;
  }

  submit() {
    this.newsletterFormTarget.setAttribute("action", this.url());
  }
}
