$(function () {
  $("[data-verification-popup]").each(function () {
    var $this = $(this);
    var $popup = $(this).next(".verificationPopup");

    $this
      .on("mouseover", function () {
        $popup.show();
      })
      .on("mouseleave", function () {
        $popup.hide();
      });
  });
});
