import { Controller } from "stimulus";
import { Colors } from "./colors";

export default class extends Controller {
  static targets = ["container", "canvas"];

  private canvasTarget: HTMLElement;

  connect(): void {
    import(/* webpackChunkName: "chart.js" */ "chart.js").then((Chart) => {
      Chart.defaults.global.defaultFontStyle = "600";
      Chart.defaults.multicolorLine = Chart.defaults.line;
      Chart.controllers.multicolorLine = Chart.controllers.line.extend({
        draw: function (ease) {
          var startIndex = 0,
            meta = this.getMeta(),
            points = meta.data || [],
            colors = this.getDataset().colors,
            area = this.chart.chartArea,
            originalDatasets = meta.dataset._children.filter(function (data) {
              return !isNaN(data._view.y);
            });

          function _setColor(newColor, meta) {
            meta.dataset._view.borderColor = newColor;
          }

          if (!colors) {
            Chart.controllers.line.prototype.draw.call(this, ease);
            return;
          }

          for (var i = 2; i <= colors.length; i++) {
            if (colors[i - 1] !== colors[i]) {
              _setColor(colors[i - 1], meta);
              meta.dataset._children = originalDatasets.slice(startIndex, i);
              meta.dataset.draw();
              startIndex = i - 1;
            }
          }

          meta.dataset._children = originalDatasets.slice(startIndex);
          meta.dataset.draw();
          meta.dataset._children = originalDatasets;

          points.forEach(function (point) {
            point.draw(area);
          });
        },
      });

      Chart.defaults.global.legend.display = false;

      var config = {
        type: "multicolorLine",
        data: {
          labels: JSON.parse(this.data.get("labels")),
          datasets: [
            {
              data: [0, 10, 80, 10, 0],
              pointRadius: 0,
              borderColor: Colors.OFRI_BLUE,
              backgroundColor: Colors.GRAY,
              fill: false,
              colors: [
                "",
                Colors.GRAY,
                Colors.OFRI_BLUE,
                Colors.OFRI_BLUE,
                Colors.GRAY,
              ],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          responsive: true,
          tooltips: {
            enabled: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  color: Colors.GRAY,
                },
              },
            ],
            yAxes: [
              {
                display: false,
                scaleLabel: {
                  display: false,
                  labelString: "Value",
                },
                gridLines: {
                  color: Colors.WHITE,
                },
                ticks: {
                  display: false,
                  suggestedMin: -10,
                  suggestedMax: 100,
                },
              },
            ],
          },
        },
      };

      var ctx = this.canvasTarget;
      new Chart(ctx, config);
    });
  }
}
