import { Controller } from "stimulus";
import { debounce } from "lodash-es";
import serialize from "form-serialize";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";

export default class extends Controller {
  static targets = ["username"];

  connect() {
    // used as on key trigger
    this.onUsernameChange = debounce(this.checkUsername, 300);
    this.fetcher = new Fetcher();
  }

  checkUsername() {
    this.fetcher
      .request(this.usernameTarget.dataset.url, {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: serialize(this.element),
      })
      .then((json) => {
        if (json.valid) {
          this.usernameTarget.classList.remove("is-invalid");
          this.usernameTarget.classList.add("is-valid");
        } else {
          this.usernameTarget.classList.remove("is-valid");
          this.usernameTarget.classList.add("is-invalid");
        }
      })
      .catch(() => {
        serverErrorNotification();
      });
  }
}
