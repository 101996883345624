import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";

export default class extends Controller {
  static targets = ["cta", "showLink"];

  connect() {
    if (this.hasCtaTarget) {
      this.ctaTargets.forEach((target) => {
        target.classList.remove("d-none");

        $(target).colorbox({
          inline: true,
          width: "40%",
          onOpen: () => {
            const endpoint = this.data.get("track");

            if (!this.tracked && endpoint) {
              const fetcher = new Fetcher();
              fetcher
                .request(endpoint, {
                  method: "post",
                })
                .then(() => (this.tracked = true));
            }
          },
        });
      });
    }
  }

  redirectToProposal(event) {
    window.location = event.currentTarget.dataset.path;
  }
}
