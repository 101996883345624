$(function () {
  function formatMoney(value) {
    return "CHF " + Math.round(value) + ".-";
  }

  var CustomEstimator = function (form) {
    this.form = form;

    this.init = function () {
      this.initMeasureInput();
    };

    this.initMeasureInput = function () {
      this.setMeasureInputPlaceholder();
      this.measureInput().on("change keyup", this.updateChartPrices.bind(this));
    };

    this.setMeasureInputPlaceholder = function () {
      this.measureInput().attr("placeholder", this.defaultMeasureValue());
    };

    this.measureInput = function () {
      return this.form.find(".measure-input");
    };

    this.defaultMeasureValue = function () {
      var exactValue = this.originalPrice("medium") / this.unitPrice();
      return Math.round(exactValue);
    };

    this.originalPrice = function (type) {
      return parseInt(this.form.attr("data-original-" + type + "-price"));
    };

    this.unitPrice = function () {
      return parseInt(this.form.attr("data-unit-price"));
    };

    this.updateChartPrices = function () {
      this.updateChartPrice("low", this.currentSidePrice("low"));
      this.updateChartPrice("medium", this.currentMediumPrice());
      this.updateChartPrice("high", this.currentSidePrice("high"));
      this.updateSummaryPrice("low", this.currentSidePrice("low"));
      this.updateSummaryPrice("high", this.currentSidePrice("high"));
    };

    this.updateChartPrice = function (type, price) {
      var text = $("#price-distribution").find(
        'text[text-anchor="' + type + '"]',
      );
      var tspan = text.find("tspan").last();
      tspan.text(formatMoney(price));
    };

    this.currentSidePrice = function (type) {
      return this.currentMediumPrice() * this.priceMultiplier(type);
    };

    this.currentMediumPrice = function () {
      return this.currentMeasureValue() * this.unitPrice();
    };

    this.currentMeasureValue = function () {
      var val = parseInt(this.measureInput().val());
      if (isNaN(val)) return this.defaultMeasureValue();

      return val;
    };

    this.priceMultiplier = function (type) {
      return this.originalPrice(type) / this.originalPrice("medium");
    };

    this.updateSummaryPrice = function (type, price) {
      var text = $('.price-summary strong[itemprop="' + type + 'Price"]');
      text.text(formatMoney(price));
    };
  };

  var PriceEstimatorPage = function (page) {
    this.page = page;

    this.init = function () {
      this.initializePriceDistributionChart();
      this.initCustomEstimation();
    };

    this.initializePriceDistributionChart = function () {
      var chartContainerEl = document.getElementById("canvas-container");
      if (!chartContainerEl) {
        return;
      }

      var chartSize = {
        w: 620,
        h: 300,
      };
      var paper = new Raphael(chartContainerEl, chartSize.w, chartSize.h);

      paper.canvas.setAttribute("preserveAspectRatio", "xMinYMin");
      paper.canvas.setAttribute(
        "viewBox",
        "0 0 " + paper.width + " " + paper.height,
      );
      paper.canvas.setAttribute("width", "100%");
      paper.canvas.setAttribute("height", "100%");

      // Grab data
      const dataContainer = $("#canvas-container");
      const price_stats = dataContainer.data("prices");
      const lowText = dataContainer.data("low-text");
      const highText = dataContainer.data("high-text");
      const mediumText = dataContainer.data("medium-text");

      // Line chart
      paper.linechart(
        // left/top/right/bottom anchors
        0,
        50,
        chartSize.w,
        chartSize.h,
        [0, 120, 240, 480, 720, 840, 960],
        [1, 13.2, 30, 80, 30, 13.2, 1],
        {
          smooth: true,
          colors: ["#3056A7"],
          symbol: "",
          shade: true,
        },
      );

      // Minimum Price
      var circle_min = paper.circle(153, 235, 8);
      circle_min.attr({
        fill: "#3056A7",
        stroke: "#FFF",
        "stroke-width": 2,
      });
      paper.text(95, 215, `${lowText}\n${formatMoney(price_stats.low)}`).attr({
        "text-anchor": "low",
        "font-size": "18px",
      });

      // Mean Price
      var circle_mean = paper.circle(296, 60, 8);
      circle_mean.attr({
        fill: "#3056A7",
        stroke: "#FFF",
        "stroke-width": 2,
      });
      paper
        .text(300, 30, `${mediumText}\n${formatMoney(price_stats.medium)}`)
        .attr({
          "text-anchor": "medium",
          "font-size": "18px",
        });

      // Maximum Price
      var circle_max = paper.circle(443, 235, 8);
      circle_max.attr({
        fill: "#3056A7",
        stroke: "#FFF",
        "stroke-width": 2,
      });
      paper
        .text(500, 215, `${highText}\n${formatMoney(price_stats.high)}`)
        .attr({
          "text-anchor": "high",
          "font-size": "18px",
        });
    };

    this.initCustomEstimation = function () {
      var form = this.page.find(".custom-estimation");
      if (form.length > 0) {
        var customEstimator = new CustomEstimator(form);
        customEstimator.init();
      }
    };
  };

  var page = $("#price-estimator");
  if (page.length > 0) {
    import(/* webpackChunkName: "raphael" */ "raphael").then((module) => {
      window.Raphael = module.default;
      import(/* webpackChunkName: "graphael" */ "../lib/graphael").then(
        (module) => {
          import(/* webpackChunkName: "gline" */ "../lib/gline").then(
            (module) => {
              var pageHandler = new PriceEstimatorPage(page);
              pageHandler.init();
            },
          );
        },
      );
    });
  }
});
