import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cancelLink"];

  connect() {
    $(this.cancelLinkTarget).colorbox({
      width: "40%",
    });
  }
}
