import { Controller } from "stimulus";

export default class extends Controller {
  switchIcon(event) {
    const iconElement = event.currentTarget.lastElementChild;

    if (event.currentTarget.ariaExpanded === "true") {
      iconElement.classList.remove("fa-chevron-up");
      iconElement.classList.add("fa-chevron-down");
    } else {
      iconElement.classList.remove("fa-chevron-down");
      iconElement.classList.add("fa-chevron-up");
    }
  }
}
