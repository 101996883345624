import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";

export default class extends Controller {
  static targets = ["radioButton"];

  static values = {
    url: String,
  };

  connect() {
    this.fetcher = new Fetcher();
  }

  updateMatchingMode(event) {
    event.preventDefault();
    this.fetcher
      .request(this.urlValue, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.formData()),
      })
      .then((result) => {
        window.location = result.location;
      })
      .catch(() => {
        serverErrorNotification();
      });
  }

  matchingMode(value) {
    return value !== "nil" ? value : null;
  }

  formData() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    params.matching_mode = this.matchingMode(this.radioButtonTarget.id);

    return params;
  }
}
