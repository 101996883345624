import { Controller } from "stimulus";

const CHECKBOX_CHECKED_CLASS = "checked";
const OPTION_SELECTED_CLASS = "selected";

export default class extends Controller {
  static targets = [
    "selectOptionWrapper",
    "checkboxesOptionsWrapper",
    "lowerBoundarySliderElement",
    "upperBoundarySliderElement",
    "lowerBoundaryValue",
    "upperBoundaryValue",
  ];

  private checkboxesOptionsWrapperTarget: HTMLElement;

  private selectOptionWrapperTarget: HTMLElement;

  private lowerBoundaryValueTarget: HTMLElement;

  private lowerBoundarySliderElementTarget: HTMLInputElement;

  private upperBoundaryValueTarget: HTMLElement;

  private upperBoundarySliderElementTarget: HTMLInputElement;

  connect(): void {
    this.selectOptionWrapperTarget.onchange = this.onRadioOptionChange;
    this.checkboxesOptionsWrapperTarget.onchange = this.onCheckboxOptionChange;
  }

  onRadioOptionChange(event): void {
    const formInputs: NodeListOf<HTMLElement> = document.querySelectorAll(
      `label[data-attribute="${event.target.name}"]`,
    );

    formInputs.forEach(function (item) {
      item.classList.remove(OPTION_SELECTED_CLASS);
    });

    event.target.parentElement.classList.add(OPTION_SELECTED_CLASS);
  }

  onCheckboxOptionChange(event): void {
    event.target.parentElement.classList.toggle(CHECKBOX_CHECKED_CLASS);
  }

  refreshPriceBoundaries(): void {
    const priceArray = [
      Number(this.lowerBoundarySliderElementTarget.value),
      Number(this.upperBoundarySliderElementTarget.value),
    ];
    this.lowerBoundaryValueTarget.innerText = Math.min(
      ...priceArray,
    ).toString();
    this.upperBoundaryValueTarget.innerText = Math.max(
      ...priceArray,
    ).toString();
  }
}
