import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["reason", "details", "detailsWrapper"];

  connect() {
    this.onChange();
  }

  onChange() {
    if (this.otherReasonSelected) {
      this.detailsWrapperTarget.classList.remove("hidden");
      this.detailsTarget.required = true;
    } else {
      this.detailsWrapperTarget.classList.add("hidden");
      this.detailsTarget.required = false;
    }
  }

  onSubmit(event) {
    if (this.otherReasonSelected && this.blankDetails) {
      event.preventDefault();
      event.stopPropagation();
      this.detailsTarget.value = "";
      this.detailsTarget.focus();
    }
  }

  get otherReasonSelected() {
    return this.selectedValue === "no_reason_given";
  }

  get blankDetails() {
    return this.detailsTarget.value.replace(/\s+/, "").length === 0;
  }

  get selectedValue() {
    const item = this.reasonTargets.find((item) => item.checked);
    return item ? item.value : null;
  }
}
