import { Controller } from "stimulus";
import { notification } from "../components/notification";

export default class extends Controller {
  static targets = [
    "question",
    "results",
    "submit",
    "positiveVotes",
    "negativeVotes",
    "positiveVotesPercentage",
    "negativeVotesPercentage",
  ];

  private submitTargets: HTMLElement;

  private resultsTarget: HTMLElement;

  private positiveVotesPercentageTarget: HTMLElement;

  private negativeVotesPercentageTarget: HTMLElement;

  private positiveVotesTarget: HTMLElement;

  private negativeVotesTarget: HTMLElement;

  private questionTarget: HTMLElement;

  private voteSubmitted(event): void {
    this.showVotesResult(event);
  }

  private submitError(event): void {
    notification(this.data.get("submit-error"), "error");
    this.showVotesResult(event);
  }

  private showVotesResult(event): void {
    this.questionTarget.classList.add("d-none");
    this.resultsTarget.classList.remove("d-none");
    const response = event.detail[0];
    const positiveVotes = response["yes"];
    const negativeVotes = response["no"];
    const votes = positiveVotes + negativeVotes;
    const negativeRatio = Math.round((negativeVotes / votes) * 100);
    const positiveRatio = Math.round((positiveVotes / votes) * 100);
    $(this.positiveVotesPercentageTarget).html(`${positiveRatio.toString()}%`);
    $(this.negativeVotesPercentageTarget).html(`${negativeRatio.toString()}%`);
    $(this.positiveVotesTarget).attr("style", `width: ${positiveRatio}%`);
    $(this.negativeVotesTarget).attr("style", `width: ${negativeRatio}%`);
    $(this.positiveVotesTarget).attr("aria-valuenow", `${positiveRatio}%`);
    $(this.negativeVotesTarget).attr("aria-valuenow", `${negativeRatio}%`);
    this.resultsTarget.classList.remove("d-none");
  }
}
