import { Controller } from "stimulus";

export default class extends Controller {
  onShare(event) {
    event.preventDefault();
    window.open(
      event.target.href,
      "targetWindow",
      "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250",
    );
  }
}
