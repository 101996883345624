import { Controller } from "stimulus";
import { notification } from "../../webpack/javascripts/components/notification";

export default class extends Controller {
  onAddToWatchlist(event): void {
    const json = event.detail[0];
    this.element.innerHTML = json.partial;
    notification(json.message, "success");
  }
}
