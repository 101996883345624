import { Controller } from "stimulus";

export default class extends Controller {
  // NOTE: to work around scrolling behavior to breadcrumbs that are rendered
  // outside of single page layout this controller was introduced to be used
  // alongside turbo rails
  connect(): void {
    const url = window.location.href;

    // do not hijack scroll if there is already an anchor in the url
    if (url.includes("#")) {
      return;
    }

    const element = document.getElementById("breadcrumbs");

    // make sure it work ih Lookbook
    if (!element) {
      return;
    }

    // naive workaround for DOM loading race condition
    // (so we can avoid using mutation observer)
    setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  }
}
