import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { serverErrorNotification } from "../components/notification";

export default class extends Controller {
  static values = { path: String };

  static targets = ["type", "category", "container"];

  connect() {
    this.fetcher = new Fetcher();
  }

  search(event) {
    event.stopPropagation();

    this.fetch(this.path());
  }

  searchCategory(event) {
    this.resetCategories(event.params.category);

    const path = `${this.path()}&category_id=${event.params.category}`;
    this.fetch(path);
  }

  resetCategories(currentCategory) {
    this.categoryTargets.forEach((target) => {
      target.classList.remove("active");
      if (currentCategory == target.dataset.tradesmanProfileCategoryParam)
        target.classList.add("active");
    });
  }

  fetch(path) {
    this.fetcher
      .request(path, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.containerTarget.innerHTML = response.html;
      })
      .catch(() => serverErrorNotification());
  }

  currentTypes() {
    const types = [];

    this.typeTargets.forEach((target) => {
      const checkBox = target.firstElementChild;
      if (checkBox.checked) types.push(checkBox.value);
    });

    return types;
  }

  path() {
    let path = this.pathValue;
    const types = this.currentTypes();

    if (types.length > 0) path += `&types=${types.join(",")}`;
    return path;
  }
}
