import { Controller } from "stimulus";
import { escape, template, truncate } from "lodash-es";
import { Fetcher } from "../fetcher";
import { notification } from "../components/notification";

export default class extends Controller {
  static values = {
    deleteTemplateTranslation: String,
    templateDeletedTranslation: String,
    templateCreatedTranslation: String,
  };

  static targets = [
    "modal",
    "form",
    "title",
    "text",
    "divider",
    "dropdownMenu",
    "savedRepliesMenu",
    "savedReplyMenuItemTemplate",
  ];

  connect() {
    import("sweetalert2/src/sweetalert2.scss");
    this.fetcher = new Fetcher({
      parseErrorResponse: true,
      form: this.formTarget,
    });
    this.menuItemTemplate = template(
      this.savedReplyMenuItemTemplateTarget.innerHTML,
    );
    this.savedReplies = JSON.parse(
      this.savedReplyMenuItemTemplateTarget.dataset.replies,
    );
    this.currentUserId =
      this.savedReplyMenuItemTemplateTarget.dataset.currentUserId;

    this._rebuildSavedRepliesMenu();
  }

  _rebuildSavedRepliesMenu() {
    this.savedRepliesMenuTarget.innerHTML = "";
    this._showDivider();

    this.savedReplies.forEach((reply) => {
      const data = {
        title: escape(truncate(reply.title, { length: 28 })),
        text: escape(reply.text),
        saved_reply_id: reply.id,
        action: "click->saved-replies#populateText",
        delete_action: "click->saved-replies#delete",
        delete_url: `/users/${this.currentUserId}/saved_replies/${reply.id}`,
        csrf_token: document
          .querySelector("meta[name='csrf-token']")
          .getAttribute("content"),
      };

      this.savedRepliesMenuTarget.innerHTML += this.menuItemTemplate(data);
    });
  }

  _showDivider() {
    if (this.savedReplies.length === 0) {
      this.dividerTarget.style.display = "none";
    } else {
      this.dividerTarget.style.display = "block";
    }
  }

  populateText(event) {
    this.textTarget.value = event.currentTarget.dataset.text;
    // NOTE: we do have a event on input event listener in the form; trigger it explicitly
    const inputEvent = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    this.textTarget.dispatchEvent(inputEvent);
  }

  create(event) {
    event.preventDefault();

    const url = this.formTarget.action;

    this.fetcher
      .request(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          saved_reply: {
            title: this.titleTarget.value,
            text: this.textTarget.value,
          },
        }),
      })
      .then((response) => {
        if (response.error) {
          notification(response.error, "error");
        } else {
          notification(this.templateCreatedTranslationValue, "success");
          this._addNewSavedReply(response);
          $(this.modalTarget).modal("hide");
          this.titleTarget.value = "";
        }
      });
  }

  _addNewSavedReply(savedReply) {
    for (let i = 0; i < this.savedReplies.length; i += 1) {
      if (this.savedReplies[i].title === savedReply.title) {
        this.savedReplies[i].text = savedReply.text;
        this._rebuildSavedRepliesMenu();
        return;
      }
    }

    this.savedReplies.push(savedReply);
    this._rebuildSavedRepliesMenu();
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();

    const { deleteUrl, savedReplyId, confirmText, cancelText, csrfToken } =
      event.currentTarget.dataset;

    import("sweetalert2/src/sweetalert2").then((module) => {
      module.default
        .fire({
          text: `${this.deleteTemplateTranslationValue}: ${this.findTemplateById(savedReplyId).title}?`,
          type: "question",
          showCancelButton: true,
          confirmButtonText: confirmText,
          cancelButtonText: cancelText,
          customClass: {
            confirmButton: "btn btn-primary me-3",
            cancelButton: "btn btn-light",
            icon: "confirmation-icon",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.fetcher
              .request(deleteUrl, {
                method: "delete",
                headers: { "X-CSRF-Token": csrfToken },
              })
              .then((response) => {
                if (response.error) {
                  notification(response.error, "error");
                } else {
                  notification(this.templateDeletedTranslationValue, "success");
                  this._removeSavedReply(savedReplyId);
                }
              });
          }
        });
    });
  }

  _removeSavedReply(savedReplyId) {
    for (let i = 0; i < this.savedReplies.length; i += 1) {
      if (this.savedReplies[i].id.toString() === savedReplyId) {
        this.savedReplies.splice(i, 1);
        this._rebuildSavedRepliesMenu();
        break;
      }
    }
  }

  findTemplateById(id) {
    for (let i = 0; i < this.savedReplies.length; i += 1) {
      if (this.savedReplies[i].id.toString() === id) {
        return this.savedReplies[i];
      }
    }
  }
}
